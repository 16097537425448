import {
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import { Colours } from "../../../assets/global/Theme-variable";
import CreateIcon from "@mui/icons-material/Create";
import {
	restrictToVerticalAxis,
	snapCenterToCursor,
	restrictToParentElement,
} from "@dnd-kit/modifiers";
import GridContext, { GridProvider } from "../../../context/GridContext";
import DoneIcon from "@mui/icons-material/Done";
import Main from "./Main";
import {
	DndContext,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	TouchSensor,
	MouseSensor,
} from "@dnd-kit/core";
import {
	sortableKeyboardCoordinates,
	arraySwap,
} from "@dnd-kit/sortable";
import FunctionBar from "./FunctionBar";
import colours from "../data/colours";

export default function MainGrid({
	list,
	onDrop,
	openItem,
	setItem,
	item,

	openEditBar,
	setList,
	editBar,
	errorMessage,
	setErrorMessage,
}) {
	const [open, setOpen] = React.useState(false);
	const [editName, setEditName] = React.useState(false);
	const [showIcon, setShowIcon] = React.useState(false);

	const {
		items,
		listArea,
		draggingItem,
		setDraggingItem,
		layout,
		variant,
		setVariant,
		bottomLayout,
		setNewChanges,
	} = React.useContext(GridContext);

	const handleOpen = (item) => {
		setItem(item);
		openItem !== item && openEditBar(true);
		openItem === item && openEditBar(!editBar);
	};

	const saveName = (event) => {
		setEditName(false);
	};
	const listType = [
		{
			button: 1,
			colour: "none",
			row: "6/7",
			col: "1 /10 ",
			type: "bottom",
		},
		{
			button: 2,
			colour: "none",
			row:
				listArea?.tillName === "L2"
					? "2/6"
					: listArea === "Function List"
					? "4/6"
					: "2/6",
			col:
				listArea?.tillName === "L2"
					? "4 / 10"
					: listArea === "Function List"
					? "3/5"
					: "4/10",
			type: "large",
		},
		{
			button: 3,
			colour: colours[63],
			row: "1/4",
			col: "1/4",
			type: "standard",
		},
		{
			button: 4,
			colour: colours[63],
			row: "4/6",
			col: "1/4",
			type: "keypad",
		},
		{
			button: 5,
			colour: colours[63],
			row: "1/2",
			col: "4 / 10",
			type: "title",
		},
	];

	const keypad = [
		{
			value: 7,
			col: 1,
			row: 1,
		},

		{
			value: 8,
			col: 1,
			row: 1,
		},
		{
			value: 9,
			col: 1,
			row: 1,
		},
		{
			value: "CLR",
			col: 1,
			row: 2,
		},
		{
			value: 4,
			col: 1,
			row: 1,
		},
		{
			value: 5,
			col: 1,
			row: 1,
		},
		{
			value: 6,
			col: 1,
			row: 1,
		},
		{
			value: 1,
			col: 1,
			row: 1,
		},
		{
			value: 2,
			col: 1,
			row: 1,
		},
		{
			value: 3,
			col: 1,
			row: 1,
		},
		{
			value: "OK",
			col: 1,
			row: 2,
		},
		{
			value: "0",
			col: 1,
			row: 1,
		},
		{
			value: "00",
			col: 1,
			row: 1,
		},
		{
			value: ".",
			col: 1,
			row: 1,
		},
	];

	const positionRef = React.useRef({
		x: 0,
		y: 0,
	});
	const popperRef = React.useRef(null);
	const areaRef = React.useRef(null);

	const handleMouseMove = (event) => {
		positionRef.current = { x: event.clientX, y: event.clientY };

		if (popperRef.current != null) {
			popperRef.current.update();
		}
	};

	const [valid, setValid] = React.useState(true);
	const [activeId, setActiveId] = React.useState(null);

	const handleListNameChange = (event) => {
		const reg = new RegExp(/^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/);
		// console.log(reg.test(event.target.value));
		if (reg.test(event.target.value)) {
			setVariant((draft) => {
				draft.list_name = event.target.value;
			});
		} else {
			setValid(reg.test(event.target.value));
		}
	};

	function handleDragStart({ active }) {
		if (!active) {
			return;
		}
		setActiveId(active.id);
	}

	// function handleDragOver({ active, over }) {
	// 	setVariant((draft) => {
	// 		let newPosItem = draft?.list_details?.buttons?.find(
	// 			(item) => item.id === over.id
	// 		);
	// 		let oldPosItem = draft?.list_details?.buttons?.find(
	// 			(item) => item.id === active.id
	// 		);

	// 		if (oldPosItem.mx === newPosItem.mx && oldPosItem.my === newPosItem.my) {
	// 			let newList = [...draft.list_details.buttons];
	// 			const firstIndex = oldPosItem.index;
	// 			oldPosItem.index = newPosItem.index;
	// 			newPosItem.index = firstIndex;
	// 			// setItems(newList);
	// 			draft.list_details.buttons = newList.sort(sortItems);
	// 			// console.log(newList);
	// 		}
	// 	});
	// }
	// const sortItems = (a, b) => a?.index - b?.index;
	// console.log(variant?.list_details?.buttons);
	const getIndex = (id) =>
		variant?.list_details?.buttons?.findIndex((i) => i.id === id);
	const activeIndex = activeId ? getIndex(activeId) : -1;

	function handleDragEnd({ active, over }) {
		if (over) {
			const overIndex = getIndex(over.id);
			const overItem = variant.list_details.buttons[overIndex];

			const activeItem = variant.list_details.buttons[activeIndex];

			if (
				activeIndex !== overIndex &&
				activeItem?.my === overItem?.my &&
				activeItem?.mx === overItem?.mx
			) {
				setVariant((draft) => {
					const newbuttons = arraySwap(
						draft.list_details.buttons,
						activeIndex,
						overIndex
					);
					draft.list_details.buttons = newbuttons;
				});
			}
		}
		setActiveId(null);
		setNewChanges(true);
	}

	// const sensors = useSensors(
	// 	useSensor(PointerSensor, {
	// 		activationConstraint: {
	// 			distance: 8,
	// 		},
	// 	}),
	// 	useSensor(KeyboardSensor, {
	// 		coordinateGetter: sortableKeyboardCoordinates,
	// 	})
	// );

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
		useSensor(TouchSensor, {
			activationConstraint: { delay: 50, tolerance: 10 },
		}),
		useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
	);

	return (
		<Box
			display="grid"
			gridTemplateColumns="repeat(9, 1fr)"
			gridTemplateRows="0.3fr repeat(4, 1fr) 0.5fr"
			gap={1}
			sx={{ flex: 1, height: "100%", backgroundColor: "white", padding: 0 }}
		>
			{listType.map((object, index) => {
				return (
					<Box
						key={object.type}
						sx={{
							backgroundColor: object?.colour,
							gridRow: object?.row,
							gridColumn: object?.col,
							minHeight: "100%",
							flex: 1,
							alignItems: "stretch",
							border:
								object?.type !== "large" &&
								object?.type !== "bottom" &&
								"1px solid #999999",
						}}
					>
						{object?.type === "large" && (
							<DndContext
								sensors={sensors}
								onDragStart={handleDragStart}
								onDragEnd={handleDragEnd}
								modifiers={[restrictToParentElement, snapCenterToCursor]}
								onDragCancel={() => setActiveId(null)}
							>
								<Main
									openItem={openItem}
									setOpen={setOpen}
									open={open}
									setItem={setItem}
									handleOpen={handleOpen}
									list={list}
									listArea={listArea}
									draggingItem={draggingItem}
									setDraggingItem={setDraggingItem}
									editBar={editBar}
									setList={setList}
									errorMessage={errorMessage}
									setErrorMessage={setErrorMessage}
									onDrop={onDrop}
									activeId={activeId}
								/>
							</DndContext>
						)}
						{object.type === "bottom" && (
							<DndContext
								sensors={sensors}
								onDragStart={handleDragStart}
								onDragEnd={handleDragEnd}
								modifiers={[restrictToParentElement, snapCenterToCursor]}
								onDragCancel={() => setActiveId(null)}
							>
								<FunctionBar
									openItem={openItem}
									setOpen={setOpen}
									open={open}
									setItem={setItem}
									colNo={bottomLayout?.col}
									rowNo={1}
									handleOpen={handleOpen}
									list={list}
									draggingItem={draggingItem}
									setDraggingItem={setDraggingItem}
									editBar={editBar}
									listArea={listArea}
									onDrop={onDrop}
									key={`inner_${object.type}`}
									activeId={activeId}
								/>
							</DndContext>
						)}
						{object?.type === "keypad" && (
							<Box
								sx={{
									display: "grid",
									gridAutoFlow: "dense",
									gridTemplateColumns: `repeat(4, 1fr)`,
									gridTemplateRows: `repeat(4, 1fr)`,
									gridGap: 2,
									minHeight: "100%",
									flex: 1,
									alignItems: "stretch",
									padding: 1,
								}}
								key={`inner_${object.type}`}
							>
								{keypad.map((item, index) => (
									<Box
										sx={{
											flex: 1,
											display: "flex",
											justifyContent: "center",
											alignContent: "center",
											gridColumn: `span ${item?.col}`,
											gridRow: `span ${item?.row}`,
											border: "1px solid #999999",
										}}
										// Added this key to resolve we console errors
										key={`keypadBox_${item.value}`} // Use a unique key based on index
									>
										<Typography
											sx={{
												color: "#999999",
												alignSelf: "center",
												justifySelf: "center",
												userSelect: "none",
												fontWeight: 600,
											}}
										>
											{item?.value}
										</Typography>
									</Box>
								))}
							</Box>
						)}
						{object?.type === "standard" && (
							<Box
								sx={{
									flex: 1,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									// height: "100%",
									margin: 1,
									// backgroundColor: "#999999",
									// border: "1px solid grey",
									// margin: 2,
									minHeight: "100%",
								}}
								key={`inner_${object.type}`}
							></Box>
						)}
						{object?.type === "title" && (
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
									height: "100%",
									// gap: 2,
									userSelect: "none",
								}}
								onMouseEnter={() => setShowIcon(true)}
								onMouseLeave={() => setShowIcon(false)}
								key={`inner_${object.type}`}
								onDoubleClick={() => setEditName(!editName)}
							>
								{editName ? (
									<TextField
										value={variant?.list_name ? variant?.list_name : ""}
										onChange={handleListNameChange}
										size="small"
										// disabled={
										// 	editName === false ||
										// 	checkPermissions(userPermission, {
										// 		page: "lists",
										// 		allow: false,
										// 		action: "edit",
										// 	})
										// }
										focused={editName === true}
										InputProps={{
											disableUnderline: true,
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														// edge="end"
														onClick={saveName}
														disableRipple
													>
														<DoneIcon
															sx={{
																fontSize: 18,
																display: !showIcon && !editName && "none",
																color: Colours.gsblue,
															}}
														/>
													</IconButton>
												</InputAdornment>
											),
											inputProps: {
												style: {
													textAlign: "center",
													border: "none",
													padding: 0,
													userSelect: "none",
												},
												onBlur: () => {
													setEditName(!editName);
												},
												onKeyDown: (event) => {
													if (event.key === "Enter") {
														setEditName(!editName);
													}
												},
												onDoubleClick: () => console.log("dc"),
											},
										}}
										inputRef={(input) => input && input.focus()}
										onDoubleClick={() => console.log("double click")}
										variant="standard"
										sx={{
											padding: 0,
											color: "white",
											"& .MuiFilledInput-underline": {
												bgcolor: "none",
												// color: "black",
												border: "none",
											},
											"& :hover.MuiFilledInput-underline": {
												bgcolor: "none",
												color: "none",
												border: "none",
											},
											ml: 4,
										}}
										fullWidth
										error={!valid}
									/>
								) : (
									<>
										<Typography
											sx={{
												width: "100%",
												textAlign: "center",
												position: "relative",
											}}
										>
											{variant?.list_name}
											{showIcon && (
												<Tooltip
													title="Edit Screen Name"
													PopperProps={{
														popperRef,
														anchorEl: {
															getBoundingClientRect: () => {
																return new DOMRect(
																	positionRef.current.x,
																	areaRef.current.getBoundingClientRect().y,
																	0,
																	0
																);
															},
														},
													}}
													placement="top"
													slotProps={{
														popper: {
															modifiers: [
																{
																	name: "offset",
																	options: {
																		offset: [0, -10],
																	},
																},
															],
														},
													}}
												>
													<IconButton
														onClick={() => setEditName(!editName)}
														onMouseMove={handleMouseMove}
														ref={areaRef}
														sx={{
															position: "absolute",
															right: 3,
															top: 0,
															bottom: 0,
														}}
														disableRipple
													>
														<CreateIcon
															sx={{
																fontSize: 18,
																color: Colours.gsblue,
															}}
														/>
													</IconButton>
												</Tooltip>
											)}
										</Typography>
									</>
								)}
							</Box>
						)}
					</Box>
				);
			})}
		</Box>
	);
}
