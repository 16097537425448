import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	IconButton,
	ToggleButton,
	Tooltip,
	Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import * as React from "react";
import Box from "@mui/material/Box";
import { Colours } from "../assets/global/Theme-variable";
import MainGrid from "./grid/components/MainGrid";
import EditSide from "./grid/components/EditSide";
import colours from "./grid/data/colours";
import { useImmer } from "use-immer";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import { LuPaintbrush } from "react-icons/lu";
import { PiPaintBrushBroad } from "react-icons/pi";
import SaveIcon from "@mui/icons-material/Save";
import format from "date-fns/format";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import bottombarlayouts from "./grid/data/bottombarlayouts";
import OptionSelect from "./grid/components/OptionSelect";
import GridContext from "../context/GridContext";
import HistoryIcon from "@mui/icons-material/History";

import NewListModal from "./grid/components/NewListModal";
import SelectWithAddOption from "./grid/components/SelectWithAddOption";
import NewVariantDialog from "./grid/components/NewPageDialog";
import FunctionBarContext from "../context/FunctionBarContext";
import { useBeforeUnload, useBlocker, useLocation } from "react-router-dom";
import { DataGridContext } from "../context/DataGridContext";
import listAreas from "./grid/data/listAreas";
// import autoFill from "./datagrids/utils";
import RouterBreadcrumbs from "../components/Breadcrumbs";
import standardListLayouts from "./grid/data/standardListLayouts";
import mainlayouts from "./grid/data/mainlayouts";
import funcType from "./grid/data/funcType";
import axios from "axios";
import AutoComplete from "./grid/components/AutoComplete";
import { LayoutGroup, motion } from "framer-motion";
import { RefreshButton } from "../components/RefreshButton";
import { ArrowButton } from "../components/ArrowButton";
import AnimatedButton from "../components/AnimatedButton";
import SettingsIcon from "@mui/icons-material/Settings";
import AdvancedSettings from "./grid/components/AdvancedSettings";
import ErrorDialog from "./grid/components/ErrorDialog";
import Spinner from "../components/Spinner/Spinner";
import AccessControl, { checkPermissions } from "./AccessControl";
import { useAuth0 } from "@auth0/auth0-react";
import { useMount } from "react-use";
import smallFunctionListLayouts from "./grid/data/smallFunctionListLayouts";
import NewPageDialog from "./grid/components/NewPageDialog";
import ListVariantForm from "./grid/components/ListVariantForm";
import { getTextColour } from "./datagrids/utils";
import { CursorContextProvider } from "../components/CursorContextProvider";
import IntegratedColourPicker from "./grid/components/IntegratedColourPicker";
import NewItemModal from "./grid/components/NewItemModal";
import { LoadingButton } from "@mui/lab";
import { API_ENDPOINTS } from "../API/apiConfig";
import ListDeleteDialog from "./grid/components/ListDeleteDialog";
import { useAppContext } from "../appContext";
import { useUserState } from "../context/UserContext";

export default function ListDesignerRefactor() {
	const item = useLocation();
	const { data, setData, loading, variant, setVariant } =
		React.useContext(DataGridContext);
const userPermission = useUserState()
	const contextName = "screens";

	const [showLoader, setLoader] = React.useState(true);
	const {
		setState,
		state: { tourActive },
	} = useAppContext();

	useMount(() => {
		if (tourActive) {
			setTimeout(() => {
				setLoader(false);
				setState({ run: true });
			}, 600);
		}
	});

	const { getAccessTokenSilently } = useAuth0();

	let initialBottomList = data[contextName]?.find(
		(list) => list?.list_details?.location === "F3" && list.list_isDeleted === 0
	);

	const [list, setList] = React.useState(
		JSON.parse(
			JSON.stringify(
				item?.state !== null && item?.state?.item !== null
					? item?.state?.item
					: data[contextName].filter(
							(list) =>
								list?.list_details?.location === "L2" &&
								list.list_isDeleted === 0 &&
								list.list_variant === 0
					  )[0]
			)
		)
	);

	const [variantOptions, setVariantOptions] = React.useState(
		data[contextName]?.filter(
			(screen) =>
				screen.list_number === list.list_number && screen.list_isDeleted === 0
		)
	);
	const variantInit = data[contextName]?.find(
		(screen) =>
			screen.list_variant === 0 &&
			screen.list_number === list.list_number &&
			screen.list_isDeleted === 0
	);
	// const [variant, setVariant] = useImmer();
	const [variantNumber, setVariantNumber] = React.useState(0);

	const [bottomBarItems, setBottomBarItems] = React.useState(
		initialBottomList?.list_details?.buttons
	);
	const [isSaving, setIsSaving] = React.useState(false);

	const sortItems = (a, b) => a.list_number - b.list_number;

	const [listOptions, setListOptions] = React.useState(
		data[contextName]
			.filter(
				(list) =>
					// list?.list_details?.location !== "F3" &&
					list?.list_details?.ignoreThisList !== true &&
					list?.list_variant === 0 &&
					list?.list_isDeleted === 0
			)
			.sort(sortItems)
	);

	const [page, setPage] = React.useState(1);

	// const [priority, setPriority] = React.useState(false);
	// const [link, setLink] = React.useState(0);
	// const [listType, setListType] = React.useState("Normal Action");
	// const [stackMode, setStackMode] = React.useState("Normal Stack");
	const [advancedSettings, openAdvancedSettings] = React.useState(false);

	const [listArea, setListArea] = React.useState(
		listAreas.find((area) => area?.tillName === list?.list_details?.location)
	);	const [newMain, setNewMain] = React.useState(variantOptions.filter((item) => item.list_variant !== 0)[0]);

	const [openItem, setItem] = React.useState({});
	const [newPage, setNewPage] = React.useState(false);

	const saveButtonVariants = {
		static: "Save",
		loading: "Saving",
		success: "Saved",
	};
	const [saveText, setSaveText] = React.useState(saveButtonVariants.static);
	const [editBar, openEditBar] = React.useState(false);
	const [modalVisible, setModalVisible] = React.useState(false);
	const [newVariant, setNewVariant] = React.useState(false);
	const [newChanges, setNewChanges] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState({
		open: false,
		text: null,
		button1text: "",
		button1func: null,
		button2text: "",
		button2func: null,
	});
	const [deleteErrorMessage, setDeleteErrorMessage] = React.useState({
		open: false,
		text: null,
		button1text: "",
		button1func: null,
		button2text: "",
		button2func: null,
	});
	const [pageArr, setPageArr] = React.useState([]);

	const [bottomLayout, setBottomLayout] = React.useState(bottombarlayouts[0]);
	const [openDropDown, setOpenDropdown] = React.useState(false);
	const [listHistory, setListHistory] = React.useState([]);
	const [index, setIndex] = React.useState();

	const getHistory = async (variantInit) => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.get(
				`${API_ENDPOINTS.getHistory}?table=${"list"}&ref=${parseInt(
					variantInit[`list_ref`]
				)}`,
				config
			)
			.then(function (response) {
				setListHistory(response?.data);
				// console.log(response?.data)
				const list = response?.data?.findLast((l) => l?.list_isCurrent === 1);

				setVariant(list);
				addButtonId();
				// setVariantNumber(0)
				setPageArr(
					Array.from(Array(list?.list_details?.pages), (e, i) => i + 1)
				);
				setItem(list?.list_details?.buttons[0]);
				setIndex(response?.data?.findIndex((i) => i?.list_isCurrent === 1));
				// setSelectedRow(response.data[0]);

				// handleClick("bottom");
				// handleScrollToCell(
				// response.data.find((i) => i[`${table}_isCurrent`] === 1).uid
				// );
				// handleAddRow(
				// 	response.data.find((i) => i[`${table}_isCurrent`] === 1).uid
				// );
				// setIsLoading(false);
			})
			.catch(function (error) {
				console.log("res", error);
			});
	};

	let shouldBlock = React.useCallback(
		({ currentLocation, nextLocation }) =>
			newChanges && currentLocation.pathname !== nextLocation.pathname,
		[newChanges]
	);
	let blocker = useBlocker(shouldBlock);

	function addButtonId() {
		setVariant((draft) => {
			draft?.list_details?.buttons?.map((button, index) => {
				button.id = index.toString();
				button.index = index;
				return button;
			});
		});
	}

	const handleNext = () => {
		setVariant(listHistory[index + 1]);
		addButtonId();
		setIndex((index) => index + 1);
		setNewChanges(false)
		setPage(1)

	};

	const handlePrev = () => {
		setVariant(listHistory[index - 1]);
		addButtonId();
		setIndex((index) => index - 1);
		setNewChanges(false)
		setPage(1)

	};

	React.useEffect(() => {
		// console.log('ue 1v',variant)
				getHistory(variantInit);

		// addButtonId();
		// console.log('UE')
		// console.log('ue 2v',variant)
		// setData({ ...data, draftLists: list });
	}, [setList, list, setVariant]);
	// React.useEffect(() => {
	// 	// setVariant(variantInit);
	// 	// setPageArr(
	// 	// 	Array.from(Array(variantInit?.list_details?.pages), (e, i) => i + 1)
	// 	// );
	// 	// setItem(variantInit?.list_details?.buttons[0]);

	// 	getHistory(variantInit);
	// 	// setVariant(listHistory?.find((l) => l?.list_ref === variant?.list_ref)
	// 	// addButtonId();
	// 	// setData({ ...data, draftLists: list });
	// }, [list]);

	// React.useEffect(() => {
	// // setList()
	// // 	JSON.parse(
	// // 		JSON.stringify(
	// // 			item?.state !== null && item?.state?.item !== null
	// // 				? item?.state?.item
	// // 				: data[contextName].filter(
	// // 						(list) =>
	// // 							list?.list_details?.location === "L2" &&
	// // 							list.list_isDeleted === 0 &&
	// // 							list.list_variant === 0
	// // 				  )[0]
	// // 		)
	// // 	)
	// }, [setData]);

	// console.log(listHistory?.find((l) => l?.list_ref === variant?.list_ref));
	// console.log(listHistory, variant);
	const handleCloseEditBar = () => openEditBar(false);

	const handleRevertChanges = (type, newValue) => {
		const originalList = JSON.parse(
			JSON.stringify(
				data[contextName].filter(
					(original) =>
						JSON.parse(original?.uid) === JSON.parse(variant?.uid) &&
						original.list_isDeleted === 0
				)[0]
			)
		);
		// console.log(variant);

		if (type === "list change") {
			handleListSelect2("list change", newValue);
		} else if (type === "variant change") {
			handleVariantSelect2("variant change", newValue);
		} else {
			setList(originalList);
			setVariant(
				data[contextName].find(
					(screen) =>
						// screen.list_variant !== 0 &&
						JSON.parse(screen.uid) === JSON.parse(originalList.uid) &&
						screen.list_isDeleted === 0
				)
			);
			setPageArr(
				Array.from(Array(originalList?.list_details?.pages), (e, i) => i + 1)
			);
			setPage(1);
		}
		closeDialog();
		setNewChanges(false);
	};

	const handleListSelect = (event, newValue) => {
		if (newChanges === true) {
			setErrorMessage({
				open: true,
				text: `You have unfinished changes to list '${list.list_name}'. Please save or discard any changes before continuing.`,
				button1text: "Discard Changes",
				button1func: () => handleRevertChanges("list change", newValue),
				button2text: "Save Changes",
				button2func: () => handleSubmitLayout("list change", newValue),
			});
			// setNewChanges(false);
		} else {
			handleListSelect2("null", newValue);
		}
	};

	const handleListSelect2 = (type, newValue) => {
		// console.log(newValue)
// console.log(data.screens)
		if (newValue !== null) {
			const updatedList = data?.screens?.find(
				(list) =>
					// list?.list_number === newValue?.list_number &&
					JSON.parse(list.uid) === JSON.parse(newValue.uid) &&
					list.list_isDeleted === 0
			);

			const newList = JSON.parse(JSON.stringify(updatedList));
			setList(newList);

			const options = data?.screens?.filter(
				(screen) =>
					// screen.list_variant !== 0 &&
					screen.list_number === newList.list_number &&
					screen.list_isDeleted === 0
			);
			setVariantOptions(options);
			// getHistory(newList);

			// setVariant(
			// 	options.find(
			// 		(option) =>
			// 			JSON.parse(option?.list_uid) === JSON.parse(newList.list_uid) && option.list_variant === 0
			// 	)
			// );
			if (newList?.list_details?.location === "F3") {
				setBottomBarItems(
					newList?.list_details?.buttons.filter(
						(button) => button?.pageNo === 1
					)
				);
				setBottomLayout({
					row: newList.list_details?.rows,
					col: newList.list_details?.cols,
					value: `${newList.list_details?.rows}x${newList.list_details?.cols}`,
				});
			}
			setPage(1);
			// setPageArr(
			// 	Array.from(Array(newList?.list_details?.pages), (e, i) => i + 1)
			// );

			setListArea(
				listAreas.find(
					(area) => area?.tillName === newList?.list_details?.location
				)
			);
			// setItem(newList?.list_details?.buttons[0]);
			// getHistory(newList);
		}
	};

	const handleVariantSelect = (event, newValue) => {
		if (newChanges === true) {
			setErrorMessage({
				open: true,
				text: `You have unfinished changes to list '${variant.list_name}'. Please save or discard any changes before continuing.`,
				button1text: "Discard Changes",
				button1func: () =>
					handleRevertChanges("variant change", event.target.value),
				button2text: "Save Changes",
				button2func: () =>
					handleSubmitLayout("variant change", event.target.value),
			});
		} else {
			handleVariantSelect2("null", event.target.value);
		}
	};

	const handleVariantSelect2 = (type, value) => {
		if (value !== null) {
			// console.log('here vairant select', type, value)

			setVariantNumber(value.list_variant);
			const updatedList = data[contextName]?.find(
				(list) =>
					parseInt(list.uid) === parseInt(value?.uid) &&
					list.list_isDeleted === 0
			);

			const newList = JSON.parse(JSON.stringify(updatedList));
			// console.log('new variant selected', newList);
			// setVariant(newList);
			if (newList?.list_details?.location === "F3") {
				setBottomBarItems(
					newList?.list_details?.buttons.filter(
						(button) => button?.pageNo === 1
					)
				);
				setBottomLayout({
					row: newList.list_details?.rows,
					col: newList.list_details?.cols,
					value: `${newList.list_details?.rows}x${newList.list_details?.cols}`,
				});
			}
			setListArea(
				listAreas.find(
					(area) => area?.tillName === newList?.list_details?.location
				)
			);
			// setItem(newList?.list_details?.buttons[0]);
			getHistory(newList);

			// const options = data?.screens?.filter(
			// 	(screen) =>
			// 		// screen.list_variant !== 0 &&
			// 		screen.list_number === newList.list_number
			// );
			// setVariantOptions(options);
			// console.log(options);
		}
	};

	const handleLayout = (event) => {
		setVariant((draft) => {
			//get all the current buttons
			const buttons = JSON.parse(JSON.stringify(draft.list_details.buttons));

			//get max cells per page
			const maxCells = event.target.value.col * event.target.value.row;
			console.log("max cells", maxCells);

			//get amount of pages needed
			let pages = Math.ceil(draft.list_details.buttons.length / maxCells);
			console.log("orig pages", pages);
			// create empty button array
			let buttonArr = [];

			// for each page, push the page number, minimum button index and maximum button index to button array
			for (let index = 1; index <= pages; index++) {
				buttonArr.push({
					page: index,
					// min: maxCells * (index - 1) - 1 < 0 ? 0 : maxCells * (index - 1),
					min: maxCells * (index - 1),

					max: maxCells * index - 1,
				});
			}
			//how many there should be
			const totalCells = buttonArr.at(-1).max;
let maxNum = buttons.reduce((a, b) => parseInt(a.id) < parseInt(b.id) ? b : a).id

			// if (buttons.length < totalCells) {
			// if buttons less than total cells needed to fill all pages, push more buttons to array
			for (let i = buttons.length; i <= totalCells; i++) {
				const colour1 = data.colours[0].palette_details.colours.find(
					(col) => col.colour === "#000000"
				);
maxNum = parseInt(maxNum) + 1
				const colour2 = getTextColour(colour1.colour);
				buttons.push({
					id: maxNum.toString(),
					index: maxNum,
					style: {
						background: colour1.index,
						foreground: colour2.index,
						alignmentV: 1,
						alignmentH: 1,
						font: "10B",
					},
					text: "",
					mx: 1,
					my: 1,
					pageNo: 1,
					size: 1,
					functionType: 0,
					functionValue: 0,
					hideText: 0,
				});
			}

			buttons.map((x, index) => {
				const page = buttonArr?.find(
					(button) => index >= button.min && index <= button.max
				);
				x.pageNo = page?.page;
				x.mx = 1;
				x.my = 1;

				if (event.target.value.col > 8 || event.target.value.row > 8) {
					x.style.font = "8B";
				}
				return x;
			});
			// }

			const pagestodelete = [];

			// mapping over the button array, looking at the buttons for eaxh page, if all buttons on particular pae are 'empty', pushing that page in to page to delete array
			buttonArr.forEach((arr) => {
				if (
					arr.page !== 1 &&
					buttons
						.filter((button) => button.pageNo === arr.page)
						.every((button) => button.text === "" || button.text === "||") ===
						true
				) {
					pagestodelete.push(arr.page);
				}
			});

			// console.log("pages to delete", pagestodelete);

			// filtering buttons where their page number is not in the page to delete array
			const newButtons = buttons.filter(
				(x) => !pagestodelete.includes(x.pageNo)
			);

			// console.log("new buttons", newButtons);

			// creating a new page array for the
			let newPages = Math.ceil(newButtons.length / maxCells);
			// console.log("newpages", newPages);

			let newButtonArr = [];

			for (let index = 1; index <= newPages; index++) {
				newButtonArr.push({
					page: index,
					min: maxCells * (index - 1),
					max: maxCells * index - 1,
				});
			}

			newButtons.map((x, index) => {
				const page = newButtonArr?.find(
					(button) => index >= button.min && index <= button.max
				);
				x.pageNo = page?.page;
				x.mx = 1;
				x.my = 1;

				if (event.target.value.col > 8 || event.target.value.row > 8) {
					x.style.font = "8B";
				}
				return x;
			});

			// let newPages = Math.ceil(newButtons.length / maxCells);

			// console.log("newbuttons", newButtons);
			// console.log("newpages", newPages);

			setPageArr(Array.from(Array(newPages), (e, i) => i + 1));
			draft.list_details.buttons = newButtons;
			draft.list_details.pages = newPages;
			draft.list_details.cols = event.target.value.col;
			draft.list_details.rows = event.target.value.row;

			setPage(1);
			setItem(newButtons[0]);
			setNewChanges(true);
		});
	};

	// const handleBottomLayout = (event) => {
	// 	setBottomLayout(event.target.value);
	// };

	const handleSubmitLayout = async (type, newValue) => {
		setIsSaving(true);

		const newData = [...data[contextName]];
		var foundIndex = newData.findIndex(
			(x) => JSON.parse(x.uid) === JSON.parse(variant.uid)
		);
		newData[foundIndex] = variant;
		setData({ ...data, [contextName]: newData });

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: [variant],
		};

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.post(API_ENDPOINTS.list, newRowData, config)
			.then(function (response) {
				console.log("res", response);
				setSaveText(saveButtonVariants.success);
				setTimeout(() => {
					setSaveText(saveButtonVariants.static);
					setIsSaving(false);
				}, 3000);
			})
			.catch(function (error) {
				console.log("res", error);
			});

		if (type === "list change") {
			handleListSelect2("null", newValue);
		}

		if (type === "variant change") {
			handleVariantSelect2("null", newValue);
		}

		if (type === "submit") {
			const options = newData.filter(
				(screen) =>
					// screen.list_variant !== 0 &&
					screen.list_number === list.list_number && screen.list_isDeleted === 0
			);
			setVariantOptions(options);
		}

		setNewChanges(false);

		setErrorMessage({
			open: false,
			text: null,
			button1text: "",
			button1func: null,
			button2text: "",
			button2func: null,
		});

		blocker.proceed?.();
	};
	const handleDeletePage = () => {
		if (page > 1) {
			setVariant((draft) => {
				const maxCells = variant.list_details.cols * variant.list_details.rows;
				const newButtons = [...draft.list_details.buttons].filter(
					(button) => button.pageNo !== page
				);
				const newPages = Math.round(newButtons.length / maxCells);
				let buttonArr = [];

				for (let index = 1; index <= newPages; index++) {
					buttonArr.push({
						page: index,

						min: maxCells * (index - 1) - 1 < 0 ? 0 : maxCells * (index - 1),
						max: maxCells * index - 1,
					});
				}

				newButtons.map((x, index) => {
					const page = buttonArr?.find(
						(button) => index >= button.min && index <= button.max
					);
					x.pageNo = page?.page;
					return x;
				});

				draft.list_details.pages = parseInt(newPages);
				draft.list_details.buttons = newButtons;

				setPageArr(
					Array.from(Array(draft.list_details.pages), (e, i) => i + 1)
				);
			});

			setPage(page - 1);
		}
	};

	const handlePageUp = (direction) => {
		if (page === 1 && direction === "up") {
			return;
		}
		if (page === pageArr.length && direction === "down") {
			return;
		} else {
			setVariant((draft) => {
				const maxCells = draft.list_details.cols * draft.list_details.rows;
				const newButtons = [...draft.list_details.buttons];

				const swapElements = (array, index1, index2) => {
					[newButtons[index1], newButtons[index2]] = [
						newButtons[index2],
						newButtons[index1],
					];
				};

				const newPages = Math.round(newButtons.length / maxCells);

				const startIndex = newButtons.findIndex(
					(button) => button.pageNo === page
				);

				for (let index = startIndex; index < startIndex + maxCells; index++) {
					swapElements(
						newButtons,
						index,
						direction === "up" ? index - maxCells : index + maxCells
					);
				}

				let buttonArr = [];
				for (let index = 1; index <= newPages; index++) {
					buttonArr.push({
						page: index,
						min:
							maxCells * (index - 1) - 1 < 0 ? 0 : maxCells * (index - 1) - 1,
						max: maxCells * index - 1,
					});
				}
				newButtons.map((x, index) => {
					const page = buttonArr?.find(
						(button) => index >= button.min && index <= button.max
					);

					x.pageNo = page?.page;
					return x;
				});

				draft.list_details.pages = parseInt(newPages);
				draft.list_details.buttons = newButtons;
				const newPageNo = direction === "up" ? page - 1 : page + 1;

				setPage(newPageNo);
				setNewChanges(true);
			});
		}
	};

	const handleNewPage = (event) => {
		if (event.target.value === "buttonClick") {
			setNewPage(true);
		} else {
			setPage(event.target.value);
		}
	};


	const closeDialog = () => {
		setErrorMessage({
			...errorMessage,
			open: false,
		});
	};

	const [copyStyles, setCopyStyles] = React.useState(false);
	const [toggleHistory, handleToggleHistory] = React.useState(false);

	const [selectedItems, setSelectedItems] = React.useState([]);

	const [bulkBackground, setBulkBackground] = React.useState(
		data?.colours[0].palette_details.colours[36]
	);

	const [bulkForeground, setBulkForeground] = React.useState(
		data?.colours[0].palette_details.colours[63]
	);

	const handleBulkForeground = (colour) => {
		let foreground = data.colours[0].palette_details.colours.find(
			(col) => col.colour === colour
		);
		setBulkForeground(foreground);
	};

	const handleBulkBackground = (colour) => {
		let background = data.colours[0].palette_details.colours.find(
			(col) => col.colour === colour
		);

		setBulkBackground(background);
	};

	const [newItemModalOpen, setNewItemModalOpen] = React.useState(false);

	const value = {
		list,
		variant,
		setList,
		page,
		setPage,
		listArea,

		setListArea,
		setListOptions,
		newItemModalOpen,
		setNewItemModalOpen,
		setVariantOptions,
		setPageArr,
		bulkBackground,
		bulkForeground,
		setVariant,
		bottomLayout,
		setItem,
		openItem,
		handleSubmitLayout,
		handleRevertChanges,
		newChanges,
		setNewChanges,
		copyStyles,
		setCopyStyles,
		selectedItems,
		setSelectedItems,
		errorMessage,
		setErrorMessage,
		closeDialog,
	};

	const bottomValues = {
		bottomBarItems,
		setBottomBarItems,
		list,
		setList,
		listArea,
		setListArea,
		page,
		setPage,
		newChanges,
		setNewChanges,
	};

	const autoFill = (list, items) => {
		const cellCount = list.list_details.cols * list.list_details.rows;

		const pages =
			Math.round(items?.length / cellCount) > 0
				? Math.round(items?.length / cellCount)
				: 1;

		let buttonArr = [];
		for (let index = 1; index <= pages; index++) {
			buttonArr.push({
				page: index,
				min: cellCount * (index - 1) - 1 < 0 ? 0 : cellCount * (index - 1) - 1,
				max: cellCount * index - 1,
			});
		}
		const maxCells = cellCount * pages - 1;

		list.list_details.pages = pages;

		for (let i = 0; i <= maxCells; i++) {
			// const colour1 = data.colours[0].palette_details.colours.random();
			const colour1 = data.colours[0].palette_details.colours.find(
				(col) => col.colour === "#000000"
			);
			const colour2 = getTextColour(colour1.colour);
			list.list_details.buttons.push({
				id: i.toString(),
				index: i,
				style: {
					background: colour1.index,
					foreground: colour2.index,
					alignmentV: 1,
					alignmentH: 1,
					font: "10B",
				},
				text: items[i]?.plu_name !== undefined ? items[i].plu_name : "",
				mx: 1,
				my: 1,
				pageNo: 1,
				size: 1,
				functionType: items[i]?.plu_number !== undefined ? 21 : 0,
				functionValue:
					items[i]?.plu_number !== undefined
						? parseInt(items[i].plu_number)
						: 0,
				hideText: 0,
			});
		}

		list.list_details.buttons.map((x, index) => {
			const page = buttonArr?.find(
				(button) => index >= button.min && index <= button.max
			);
			x.pageNo = page?.page;
		});

	};

	const handleCopyFormat = () => {
		handleToggleHistory(false);
		setCopyStyles(!copyStyles);
	};

	const toggleHistoryClick = () => {
		setCopyStyles(false);
		handleToggleHistory(!toggleHistory);
	};
	const onSubmit = async (form) => {
		setIsSaving(true);
		const listNumber = JSON.parse(data?.screens.at(-1)?.list_number) + 1;

		let newList = {
			// uid: JSON.stringify(listNumber),
			list_name: form.name,
			list_details: {},
			// list_number: JSON.stringify(listNumber),
			// list_ref: JSON.stringify(listNumber),
		};

		newList.list_details.buttons = [];
		newList.list_details.cols = form.layout.col;
		newList.list_details.rows = form.layout.row;
		newList.list_details.selMin = form.selMin[0];
		newList.list_details.selMax = form.selMin[1];
		newList.list_details.staydown = form.staydown === true ? 1 : 0;
		newList.list_details.location = "L2";
		newList.list_variant = 0;

		autoFill(
			newList,
			form.autofilllist &&
				form.group?.items.filter(
					(item) => item.plu_number.charAt(item.plu_number.length - 1) === "0" && item.plu_isDeleted === 0
				)
		);

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: [newList],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.post(
				API_ENDPOINTS.list, // Using the endpoint from API_ENDPOINTS
				newRowData,
				config
			)
			.then(function (response) {
				console.log("res", response);
				// newList.list_number = response.data.list[0].list_number;
				newList = response.data.list[0];
				newList.uid = newList.list_uid;
				// console.log(data);
				const lists = [...data[contextName], newList];
				setData({ ...data, [contextName]: lists });

				if (newList?.list_details?.location !== "F3") {
					// console.log("here");

					const options = lists
						.filter(
							(list) =>
								list?.list_details?.location !== "F3" &&
								list?.list_details?.ignoreThisList !== true &&
								list?.list_variant === 0 &&
								list.list_isDeleted === 0
						)
						.sort(sortItems);

					setList(
						options.find(
							(list) =>
								list.list_number === newList.list_number &&
								list.list_isDeleted === 0
						)
					);
					setListOptions(options);
					const variantOptions = lists.filter(
						(screen) =>
							screen.list_number === newList.list_number &&
							screen.list_isDeleted === 0
					);

					setVariantOptions(variantOptions);

					setVariant(response.data.list[0]);

					setListArea(
						listAreas.find(
							(area) => area.tillName === newList?.list_details?.location
						)
					);
					setPage(1);

					setPageArr(
						Array.from(Array(newList.list_details.pages), (e, i) => i + 1)
					);
				}
				if (newList?.list_details?.location === "F3") {
					console.log("here 2");
					setList(newList);

					setPage(1);
					setBottomBarItems(
						newList.list_details.buttons.filter((button) => button.pageNo === 1)
					);
					setPageArr(
						Array.from(Array(newList.list_details.pages), (e, i) => i + 1)
					);
				}

				setTimeout(() => {
					setIsSaving(false);

					setModalVisible(!modalVisible);
				}, 2000);
			})
			.catch(function (error) {
				console.log("res", error);
			});
	};

	const proceedNav = () => {
		// console.log("here");
		setNewChanges(false);
		setErrorMessage({
			open: false,
			text: null,
			button1text: "",
			button1func: null,
			button2text: "",
			button2func: null,
		});
		setVariant();
		blocker.proceed?.();
		// blocker.reset?.();
		// console.log(blocker.state);
	};

	const handleNewVariant = () => {
		setOpenDropdown(false);
		setNewVariant(true);
	};

	React.useEffect(() => {
		if (blocker.state === "blocked" && !newChanges) {
			setVariant();

			blocker.reset();
		}
		if (blocker.state === "blocked") {
			setErrorMessage({
				open: true,
				text: `You have unfinished changes to list '${list.list_name}'. Please save or discard any changes before continuing.`,
				button1text: "Discard Changes",
				button1func: proceedNav,
				button2text: "Save Changes",
				button2func: handleSubmitLayout,
			});
		}
	}, [blocker, newChanges]);

	const makeCurrent = async () => {
		const token = await getAccessTokenSilently();

		// copy item history
		const rowsNew = [...listHistory];

		// find index of original current row
		const originalCurrent = listHistory.findIndex(
			(i) => i[`list_isCurrent`] === 1
		);
		const originalCurrentObj = listHistory.find(
			(i) => i[`list_isCurrent`] === 1
		);
		const newCurrent = listHistory.findIndex((i) => i.uid === variant.uid);
		const curr = listHistory.find((i) => i.uid === variant.uid);
		const newCurrentObj = { ...curr };

		newCurrentObj[`list_isCurrent`] = 1;
		newCurrentObj[`list_isDeleted`] = 0;

		const newRowData = {
			updates: [newCurrentObj],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		let postStatus;
		axios
			.post(API_ENDPOINTS["list"], newRowData, config)
			.then(function (response) {
				// postStatus = response.status;
				if (response.status === 200) {
					// let updatedData;
					// if (isNew === true) {
					const updatedRow = response?.data["list"][0];
					updatedRow.uid = response?.data["list"][0][`list_uid`];
					const newData = [...data.screens];
					// .filter(
					// 	(i) => i.uid !== rowsNew[originalCurrent].uid
					// );

					const indexToReplace = newData.findIndex(
						(row) => row.uid === originalCurrentObj.uid
					);
					// newRow[`${table}_auditTime`] =
					// 	response?.data[table][0][`${table}_auditTime`]
					// newData[originalCurrent][`list_isCurrent`] = 0 ;

					newData[indexToReplace] = updatedRow;
					setVariantOptions(
						newData.filter(
							(screen) =>
								screen.list_number === updatedRow.list_number &&
								screen.list_isDeleted === 0
						)
					);
					setVariant(updatedRow);
					const updatedData = {
						...data,
						[contextName]: [...newData],
					};
					// console.log(variant);

					// } else {
					// updatedData = {
					// 	...data,
					// 	[contextName]: data[contextName],
					// };
					// rowsNew[originalCurrent][`list_isCurrent`] = 0;
					// change new to  current
					// console.log(originalCurrent);
					// rowsNew[newCurrent][`list_isCurrent`] = 1;
					// setListHistory(rowsNew);
					if (updatedRow.list_variant === 0) {
						console.log("here");
						setListOptions(
							newData
								.filter(
									(list) =>
										// list?.list_details?.location !== "F3" &&
										list?.list_details?.ignoreThisList !== true &&
										list?.list_variant === 0 &&
										list?.list_isDeleted === 0
								)
								.sort(sortItems)
						);

						setList(updatedRow);
					}

					getHistory(updatedRow);
					setData(updatedData);
					// setSnackbar({
					// 	children: "successfully saved",
					// 	severity: "success",
					// });
				} else {
					// setSnackbar({
					// 	children: "Error, couldn't save data",
					// 	severity: "error",
					// });
				}
			})
			.catch(function (error) {
				console.log(error);
				postStatus = error.response.status;
				console.log(error.response.data.error);
				// setSnackbar({
				// 	children: error?.response?.data?.error
				// 		? error?.response?.data?.error + " - couldn't save data"
				// 		: "Error - couldn't save data",
				// 	severity: "error",
				// });
			});
	};

	return (
		<>
			{loading || data[contextName] === null ? (
				<Spinner /> // You can replace this with a loading indicator or message
			) : (
				<GridContext.Provider value={value}>
					<FunctionBarContext.Provider value={bottomValues}>
						<CursorContextProvider>
							<ErrorDialog
								errorMessage={errorMessage}
								setErrorMessage={setErrorMessage}
								discardChanges={handleRevertChanges}
								saveChanges={handleSubmitLayout}
								onClose={closeDialog}
								variantOptions={variantOptions}
								variant={variant}
								setNewMain={setNewMain}
								newMain={newMain}
							/>
							<ListDeleteDialog
								deleteErrorMessage={deleteErrorMessage}
								setDeleteErrorMessage={setDeleteErrorMessage}
								discardChanges={handleRevertChanges}
								saveChanges={handleSubmitLayout}
								isSaving={isSaving}
								setIsSaving={setIsSaving}
								onClose={closeDialog}
								variantOptions={variantOptions}
								variant={variant}
								setNewMain={setNewMain}
								newMain={newMain}
								getHistory={getHistory}
							/>
							<NewListModal
								modalVisible={modalVisible}
								setModalVisible={setModalVisible}
								onSubmit={onSubmit}
								isSaving={isSaving}
							/>
							<NewPageDialog
								open={newPage}
								setOpen={setNewPage}
								page={page}
								setPage={setPage}
							/>
							<ListVariantForm
								list={list}
								setModalVisible={setNewVariant}
								modalVisible={newVariant}
								setOpenDropdown={setOpenDropdown}
								contextName={contextName}
							/>

							<NewItemModal
								setNewItemModalOpen={setNewItemModalOpen}
								newItemModalOpen={newItemModalOpen}
							/>
							<AdvancedSettings
								modalVisible={advancedSettings}
								setModalVisible={openAdvancedSettings}
								newChanges={newChanges}
								setNewChanges={setNewChanges}
								variant={variant}
								setVariant={setVariant}
								contextName={contextName}
								variantOpts={variantOptions}
							/>
							<Box
								sx={{
									flex: 1,
									maxHeight: "100%",

									width: "100%",
									ml: 4,
									mr: 4,
									pt: 1,
								}}
							>
								<Box
									sx={{
										width: "100%",
										mb: 2,
										mt: 2,
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "flex-start",
									}}
								>
									<RouterBreadcrumbs />
									<Box
										sx={{
											width: "50%",
											display: "flex",
											flexDirection: "row",
											gap: 2,
											justifyContent: "flex-end",
											alignItems: "center",
											// pb: 0.5,
											// pt: 0.5,

											// flex: 1,
										}}
									>
										{" "}
										<Tooltip title={"Advanced Settings"}>
											<>
												<ArrowButton
													onClick={() => openAdvancedSettings(true)}
													colour={Colours.gsblue}
													icon={
														<SettingsIcon
															sx={{
																fontSize: 25,
																color: Colours.gsblue,
															}}
															className="advanced"
														/>
													}
												/>
											</>
										</Tooltip>
										<AccessControl
											userPermissions={userPermission}
											unallowedPermissions={{
												page: "screens",
												// allow: false,
												strict: false,

												action: "edit",
											}}
											renderNoAccess={() => <></>}
										>
											<Tooltip title={"Delete List"}>
												<>
													<ArrowButton
														onClick={() =>
															setDeleteErrorMessage({
																open: true,
																text:
																	variant?.list_variant === 0
																		? `Are you sure you want to delete this screen?`
																		: `Are you sure you want to delete this variant?`,
																button2text: "Delete",
																// button2func: ()=>handleDeleteList,
																button1text: "Cancel",
																button1func: closeDialog,
															})
														}
														colour={Colours.gsblue}
														icon={
															<DeleteIcon
																sx={{
																	fontSize: 25,
																	color: Colours.gsblue,
																}}
																className="deleteVariant"
															/>
														}
													/>
												</>
											</Tooltip>
										</AccessControl>
										<AccessControl
											userPermissions={userPermission}
											unallowedPermissions={{
												page: "screens",
												// allow: false,
												strict: false,

												action: "edit",
											}}
											renderNoAccess={() => <></>}
										>
											<Box sx={{ height: "100%" }}>
												<Tooltip title={"Revert to original"}>
													<>
														<RefreshButton
															onClick={() =>
																setErrorMessage({
																	open: true,
																	text: `Are you sure you want to undo all changes to list ${list.list_number} -  ${list.list_name}?`,
																	button2text: "Undo",
																	button2func: handleRevertChanges,
																	button1text: "Cancel",
																	button1func: closeDialog,
																})
															}
														/>
													</>
												</Tooltip>
											</Box>

											<LoadingButton
												// size="small"
												onClick={() => handleSubmitLayout("submit")}
												loading={isSaving}
												variant={!isSaving ? "gradient" : "outlined"}
												loadingPosition="start"
												startIcon={<SaveIcon />}
												disabled={isSaving}
												sx={{ height: "100%", width: 100 }}
											>
												<span>Save</span>
											</LoadingButton>
										</AccessControl>
									</Box>
								</Box>
								<Box
									sx={{
										width: "100%",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "space-between",
										flex: 1,
										mb: 2,
										mt: 2,
										gap: 5,
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "flex-start",
											// flex: 1,
											// width: "40%",
											gap: 1,
										}}
									>
										<AutoComplete
											options={listOptions}
											value={list}
											label={"List"}
											onChange={handleListSelect}
											type={"List"}
											data={data}
											className="listSelect"
											width={200}
											toggleOpen={() => setModalVisible(true)}
										/>
										<OptionSelect
											options={variantOptions.sort(
												(a, b) => a.list_variant - b.list_variant
											)}
											value={variantOptions?.find(
												(v) => v?.list_variant === variant?.list_variant
											)}
											label={"Variants"}
											onChange={handleVariantSelect}
											type="variant"
											width={140}
											addButton
											onClickAddButton={handleNewVariant}
											open={openDropDown}
											setOpen={setOpenDropdown}
										/>
										<OptionSelect
											options={
												variant?.list_details?.location === "L"
													? standardListLayouts
													: variant?.list_details?.location === "F"
													? smallFunctionListLayouts
													: variant?.list_details?.location === "F3"
													? bottombarlayouts
													: mainlayouts
											}
											value={
												variant?.list_details?.location === "L"
													? standardListLayouts.find(
															(layout) =>
																layout.row === variant?.list_details?.rows &&
																layout.col === variant?.list_details?.cols
													  )
													: variant?.list_details?.location === "F"
													? smallFunctionListLayouts.find(
															(layout) =>
																layout.row === variant?.list_details?.rows &&
																layout.col === variant?.list_details?.cols
													  )
													: variant?.list_details?.location === "F3"
													? bottombarlayouts.find(
															(layout) =>
																layout.row === variant?.list_details?.rows &&
																layout.col === variant?.list_details?.cols
													  )
													: mainlayouts.find(
															(layout) =>
																layout.row === variant?.list_details?.rows &&
																layout.col === variant?.list_details?.cols
													  )
											}
											type="layout"
											label={"Layout"}
											onChange={handleLayout}
											width={80}
											// disabled={
											// 	!checkPermissions(userPermission, {
											// 		page: "screens",
											// 		// allow: false,
											// 		action: "edit",
											// 	})
											// }
										/>

										{/* <Tooltip title={"Advanced Settings"}>
											<>
												<ArrowButton
													onClick={() => openAdvancedSettings(true)}
													colour={Colours.gsblue}
													icon={
														<SettingsIcon
															sx={{
																fontSize: 25,
																color: Colours.gsblue,
															}}
															className="advanced"
														/>
													}
												/>
											</>
										</Tooltip>
										<AccessControl
											userPermissions={userPermission}
											unallowedPermissions={{
												page: "screens",
												// allow: false,
												strict: false,

												action: "edit",
											}}
											renderNoAccess={() => <></>}
										>
											<Tooltip title={"Delete List"}>
												<>
													<ArrowButton
														onClick={() =>
															setErrorMessage({
																open: true,
																text:
																	variant?.list_variant === 0
																		? `Are you sure you want to delete list ${list.list_number} -  ${list.list_name}? Deleting this list will also delete all associated variants`
																		: `Are you sure you want to delete variant ${variant.list_name}?`,
																button2text: "Delete",
																button2func: handleDeleteList,
																button1text: "Cancel",
																button1func: closeDialog,
															})
														}
														colour={Colours.gsblue}
														icon={
															<DeleteIcon
																sx={{
																	fontSize: 25,
																	color: Colours.gsblue,
																}}
																className="deleteVariant"
															/>
														}
													/>
												</>
											</Tooltip>
										</AccessControl> */}
										{/* </Box> */}
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between",
												// width: "10%",
												// flex: 1,
											}}
											className="pages"
										>
											<SelectWithAddOption
												value={pageArr.find((p) => p === page)}
												onChange={handleNewPage}
												options={pageArr}
												newVariant={newVariant}
												setNewVariant={setNewVariant}
												open={newPage}
												setOpen={setNewPage}
												list={list}
												marginRight={1}
												label={"Page"}
												// width="100%"
											/>
											<AccessControl
												userPermissions={userPermission}
												unallowedPermissions={{
													page: "screens",
													// allow: false,
													action: "edit",
													strict: false,
												}}
												renderNoAccess={() => <></>}
											>
												<Tooltip
													title={
														page !== 1
															? "Move Page Up"
															: "Cannot Move First Page Up"
													}
												>
													<>
														<ArrowButton
															disabled={page === 1}
															onClick={() => handlePageUp("up")}
															colour={
																page !== 1 ? Colours.gsblue : Colours.lightGrey
															}
															icon={
																<ArrowUpwardIcon
																	sx={{
																		fontSize: 25,
																		color:
																			page !== 1
																				? Colours.gsblue
																				: Colours.lightGrey,
																	}}
																/>
															}
														/>
													</>
												</Tooltip>
												<Tooltip
													title={
														page !== pageArr?.length
															? "Move Page Down"
															: "Cannot move last page down"
													}
												>
													<>
														<ArrowButton
															colour={
																page !== pageArr?.length
																	? Colours.gsblue
																	: Colours.lightGrey
															}
															disabled={page === pageArr?.length}
															onClick={() => handlePageUp("down")}
															icon={
																<ArrowDownwardIcon
																	sx={{
																		fontSize: 25,
																		color:
																			page !== pageArr?.length
																				? Colours.gsblue
																				: Colours.lightGrey,
																	}}
																/>
															}
														/>
													</>
												</Tooltip>

												<Tooltip
													title={
														page !== 1
															? "Delete Current Page"
															: "Cannot delete first page"
													}
												>
													<>
														<ArrowButton
															disabled={page === 1}
															onClick={handleDeletePage}
															colour={
																page !== 1 ? Colours.gsblue : Colours.lightGrey
															}
															icon={
																<DeleteIcon
																	sx={{
																		fontSize: 25,
																		color:
																			page !== 1
																				? Colours.gsblue
																				: Colours.lightGrey,
																	}}
																/>
															}
														/>
													</>
												</Tooltip>
											</AccessControl>
										</Box>
									</Box>
									{/* <OptionSelect
								options={listAreas}
								value={listArea}
								label={"List Area"}
								onChange={handleListArea}
								type="area"
								width={"15%"}
								disabled={true}
							/> */}
									<Box
										sx={{
											// width: "15%",
											display: "flex",
											flexDirection: "row",
											gap: 2,
											justifyContent: "flex-end",
											alignItems: "flex-end",

											// flex: 1,
										}}
									>
										<Box
											sx={{
												// width: "5%",
												height: "100%",
												pb: 0.5,
												pt: 0.5,
												// flex: 1,
											}}
										>
											<motion.div
												style={{
													border: "1px solid #c4c4c4",
													// p: 1,
													zIndex: 10,
													borderRadius: "5px",
													display: "flex",
													flexDirection: "row",
													width: copyStyles ? "max-content" : "min-content",
													alignItems: "center",
													justifyContent: "flex-start",
												}}
												layout
												// animate={{ layout: { duration: 6 } }}
												transition={{
													opacity: { ease: "linear" },
													layout: { duration: 0.3 },
												}}
											>
												{copyStyles === true && (
													<motion.div
														data-isopen={copyStyles}
														initial={{ x: -20, opacity: 0 }}
														animate={{ x: 0, opacity: 1 }}
														exit={{ x: -20, opacity: 0 }}
														transition={{ duration: 0.3 }}
														style={{
															display: "flex",
															flexDirection: "row",
															height: "100%",
														}}
														layout
													>
														<IntegratedColourPicker
															selectedColour={bulkBackground?.colour}
															handleColourChange={handleBulkBackground}
															type="bulk-bg"
															label="Colour"
														/>
														<IntegratedColourPicker
															selectedColour={bulkForeground?.colour}
															handleColourChange={handleBulkForeground}
															type="bulk-f"
															label="Colour"
														/>
													</motion.div>
												)}
												<motion.div layout>
													<ToggleButton
														size="small"
														value={copyStyles}
														selected={copyStyles}
														onClick={handleCopyFormat}
														aria-label="bold"
														sx={{
															border: "none",
															margin: 0.1,
															background: "none",
														}}
														// layout
													>
														{/* <motion.div layout> */}
														<PiPaintBrushBroad
															color={Colours.gsblue}
															size={25}
															className="copyPaste"
														/>
													</ToggleButton>
												</motion.div>
											</motion.div>
										</Box>
										<Box
											sx={{
												// width: "5%",
												height: "100%",
												pb: 0.5,
												pt: 0.5,
												// flex: 1,
											}}
										>
											<motion.div
												style={{
													border: "1px solid #c4c4c4",
													// p: 1,
													zIndex: 10,
													borderRadius: "5px",
													display: "flex",
													flexDirection: "row",
													width: toggleHistory ? "max-content" : "min-content",
													alignItems: "center",
													justifyContent: "flex-start",
												}}
												layout
												// animate={{ layout: { duration: 6 } }}
												transition={{
													opacity: { ease: "linear" },
													layout: { duration: 0.3 },
												}}
											>
												{toggleHistory === true && (
													<motion.div
														data-isopen={toggleHistory}
														initial={{ x: 0, opacity: 0 }}
														animate={{ x: 0, opacity: 1 }}
														exit={{ x: 0, opacity: 0 }}
														transition={{ duration: 1 }}
														style={{
															display: "flex",
															flexDirection: "row",
															height: "100%",
														}}
														layout
													>
														<Box
															sx={{
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																gap: 2,
																width: "100%",
																// pl: 2,
																pr: 1,
															}}
														>
															<Box
																sx={{
																	display: "flex",
																	alignItems: "center",
																	justifySelf: "space-between",
																	gap: 1,
																}}
															>
																<Box
																	sx={{
																		display: "flex",
																		alignItems: "center",
																		// justifySelf: "flex-start",
																		gap: 1,
																	}}
																>
																	<IconButton
																		aria-label="rewind"
																		onClick={handlePrev}
																		disabled={index === 0}
																	>
																		<FastRewindIcon
																			sx={{
																				fontSize: 20,
																				// color: Colours.gsblue,
																			}}

																			// className="deleteVariant"
																		/>
																	</IconButton>
																</Box>
																<Typography
																	sx={{ width: 170, textAlign: "center" }}
																>
																	{variant?.list_auditTime &&
																	variant?.list_auditTime !== null
																		? format(
																				variant?.list_auditTime,
																				"h:mma do MMM yy"
																		  )
																		: "Unknown"}
																</Typography>
																<Box
																	sx={{
																		display: "flex",
																		alignItems: "center",
																		// justifyContent: "flex-start",
																		gap: 1,
																	}}
																>
																	<IconButton
																		aria-label="fast forward"
																		onClick={handleNext}
																		disabled={index >= listHistory.length - 1}
																	>
																		<FastForwardIcon
																			sx={{
																				fontSize: 20,
																				// color: Colours.gsblue,
																			}}
																			// className="deleteVariant"
																		/>
																	</IconButton>
																</Box>
															</Box>
															<Button
																variant="contained"
																aria-label="fast forward"
																onClick={makeCurrent}
																size="small"
																disabled={variant?.list_isCurrent === 1}
															>
																Make Current
															</Button>
														</Box>
													</motion.div>
												)}
												{/* {listHistory.length > 1 && ( */}
												<motion.div
													layout
													// initial={{ x: 0, y: 0, opacity: 0 }}
													// animate={{ x: 100 }}
													// initial={false}
													// exit={{ x: 0, y: 0, opacity: 0 }}
													// transition={{ duration: 0.5 }}
												>
													<ToggleButton
														size="small"
														value={toggleHistory}
														selected={toggleHistory}
														onClick={toggleHistoryClick}
														// disabled={listHistory?.length < 2}
														aria-label="bold"
														sx={{
															border: "none",
															margin: 0.1,
															background: "none",
														}}
														// layout
													>
														{/* <motion.div layout> */}
														<HistoryIcon
															color={Colours.gsblue}
															size={25}
															className="copyPaste"
														/>
													</ToggleButton>
												</motion.div>
												{/* )} */}
											</motion.div>
										</Box>
									</Box>
									{/* <Box
										sx={{
											width: "15%",
											display: "flex",
											flexDirection: "row",
											gap: 2,
											justifyContent: "flex-end",
											alignItems: "flex-end",
											pb: 0.5,
											pt: 0.5,

											// flex: 1,
										}}
									>
										<AccessControl
											userPermissions={userPermission}
											unallowedPermissions={{
												page: "screens",
												// allow: false,
												strict: false,

												action: "edit",
											}}
											renderNoAccess={() => <></>}
										>
										
											<Box sx={{ height: "100%" }}>
												<Tooltip title={"Revert to original"}>
													<>
														<RefreshButton
															onClick={() =>
																setErrorMessage({
																	open: true,
																	text: `Are you sure you want to undo all changes to list ${list.list_number} -  ${list.list_name}?`,
																	button2text: "Undo",
																	button2func: handleRevertChanges,
																	button1text: "Cancel",
																	button1func: closeDialog,
																})
															}
														/>
													</>
												</Tooltip>
											</Box>

											
											<LoadingButton
												// size="small"
												onClick={() => handleSubmitLayout("submit")}
												loading={isSaving}
												variant={!isSaving ? "gradient" : "outlined"}
												loadingPosition="start"
												startIcon={<SaveIcon />}
												disabled={isSaving}
												sx={{ height: "100%", width: "100%" }}
											>
												<span>Save</span>
											</LoadingButton>
										
										</AccessControl>
									</Box> */}
								</Box>

								{/* <Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										gap: 2,
										width: "100%",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifySelf: "flex-start",
											gap: 1,
										}}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifySelf: "flex-start",
												gap: 1,
											}}
										>
											<IconButton
												aria-label="rewind"
												onClick={handlePrev}
												disabled={index === 0}
											>
												<FastRewindIcon
													sx={{
														fontSize: 20,
														// color: Colours.gsblue,
													}}

													// className="deleteVariant"
												/>
											</IconButton>
										</Box>
										<Typography>
											{variant?.list_auditTime &&
											variant?.list_auditTime !== null
												? format(variant?.list_auditTime, "h:mma do MMM yy")
												: "No information available"}
										</Typography>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-start",
												gap: 1,
											}}
										>
											<IconButton
												aria-label="fast forward"
												onClick={handleNext}
												disabled={index >= listHistory.length - 1}
											>
												<FastForwardIcon
													sx={{
														fontSize: 20,
														// color: Colours.gsblue,
													}}
													// className="deleteVariant"
												/>
											</IconButton>
										</Box>
									</Box>
									<Button
										variant="contained"
										aria-label="fast forward"
										onClick={makeCurrent}
										size="small"
										disabled={variant?.list_isCurrent === 1}
									>
										Make Current
									</Button>
								</Box> */}

								<Box
									sx={{
										width: "100%",
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										flex: 1,
										minHeight: "80%",
									}}
								>
									<Box
										sx={{
											width: "85%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flex: 1,
											minHeight: "100%",
											mb: 4,
										}}
									>
										<motion.div
											transition={{
												layout: { duration: 0.3 },
											}}
											layout
											data-isopen={!editBar}
											initial={{ width: "100%", height: "100%" }}
											style={{ display: "flex", height: "100%" }}
										>
											<MainGrid
												openItem={openItem}
												setItem={setItem}
												editBar={editBar}
												openEditBar={openEditBar}
												errorMessage={errorMessage}
												setErrorMessage={setErrorMessage}
											/>
										</motion.div>
									</Box>
									{editBar && (
										<Box
											sx={{
												width: "15%",
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "flex-start",
												flexDirection: "column",
												minHeight: "100%",
												mb: 4,
												ml: 4,
											}}
										>
											<motion.div
												// transition={spring}
												initial={{ x: 300, opacity: 0 }}
												animate={{ x: 0, opacity: 1 }}
												exit={{ x: 300, opacity: 0 }}
												transition={{ duration: 0.3 }}
												layout
												//console.js:213 Warning: React does not recognize the `data-isOpen` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `data-isopen` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
												data-isopen={editBar}
												style={{
													display: "flex",
													flex: 1,
													height: "100%",
													justifyContent: "flex-start",
													alignItems: "flex-start",
													width: "100%",
												}}
											>
												<EditSide
													openItem={openItem}
													setItem={setItem}
													editBar={editBar}
													openEditBar={openEditBar}
													handleSubmitLayout={handleSubmitLayout}
													handleCloseEditBar={handleCloseEditBar}
												/>
											</motion.div>
										</Box>
									)}{" "}
								</Box>
							</Box>
						</CursorContextProvider>
					</FunctionBarContext.Provider>
				</GridContext.Provider>
			)}
		</>
	);
}
