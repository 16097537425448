import axios from "axios";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { useFetch } from "../views/profile/useFetch";
import { sorter, sorterPlu, contextDeletedFilter } from "../views/datagrids/utils";
import { presetServers } from "../views/grid/data/presetServers";
import { APP_URL } from "../API/apiConfig";
import { useFetch } from "../views/profile/useFetch";
import { useImmer } from "use-immer";
import { UserContext, useUserState, useUserUpdater } from "./UserContext";

const DataGridContext = React.createContext();

const DataGridContextProvider = (props) => {
	const { getAccessTokenSilently, isAuthenticated } = useAuth0();
	const userPermission = useUserState()
	// const getUser = useUserUpdater()

// const [rows, setRows] = useState([])
	const [showDeleted, setShowDeleted] = React.useState(false);
	const [variant, setVariant] = useImmer();

	const [data, setData] = useState({
		// user: loggedInUser,
		sites: null,
		screens: null,
		items: null,
		pricing: null,
		tags: null,
		majorcategories: null,
		categories: null,
		printers: null,
		staff: null,
		colours: null,
		analysisCategory: null,
		macro: null,
		function: null,
		paymentMethod: null,
		priceband: null,
		presetServers: presetServers,
		modifier: null,
		users: null,
		userTypes: null,
		policies: null,
		history: null,
	});

	const [newData, setNewData] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isRetail, setIsRetail] = useState(false);

	
	React.useEffect(() => {
		// console.log('ue2')
		if (isAuthenticated) {
			fetchArticlesSequentially();
		}
	}, []);

	React.useCallback(() => {
		console.log('ue1')
		if (userPermission !== null) {
			fetchArticlesSequentially();
		}
		if (newData) {
			setNewData(false);
		}
	}, [newData]);

	const fetchArticlesSequentially = async () => {
		const dataSets = [
			"site",
			"tag",
			"majorGroup",
			"salesGroup",
			"plu",
			"printer",
			"list",
			"server",
			"palette",
			"analysisCategory",
			"macro",
			"function",
			"paymentMethod",
			"priceband",
			"modifier",
			"user",
			"userType",
			"policy",
		];
		const fetchedDataSets = [];
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		for (const data of dataSets) {
			try {
			const api = `${APP_URL}/api/pos/getNextSetFromCache/?table=${data}&uid=0&setSize=9999`;
			const response = await axios.get(api, config);
			const res = await response.data.rows;
			fetchedDataSets.push(res);
		} catch (error) {
			console.log(error)
		}
		}
		setData({
			...data,

			items: fetchedDataSets[4] && 
				sorterPlu(fetchedDataSets[4], "plu_number"),
			
			
	
			majorcategories: fetchedDataSets[2] && 
				sorter(fetchedDataSets[2], "majorGroup_number"),
			
		
			categories: fetchedDataSets[3] && 
				sorter(fetchedDataSets[3], "salesGroup_number"),
		
		
			tags: fetchedDataSets[1] && 
				sorter(fetchedDataSets[1], "tag_ref"),
		
	
			sites: fetchedDataSets[0] && 
				sorter(fetchedDataSets[0], "site_ref"),
				
		
			screens: fetchedDataSets[6] && 
				sorter(fetchedDataSets[6], "list_number"),
			

			printers: fetchedDataSets[5] && 
				fetchedDataSets[5],
			
			staff: fetchedDataSets[7] && 
				sorter(fetchedDataSets[7], "server_number").filter((item) => item.server_details.userLevel !== 9),
			colours: fetchedDataSets[8] && fetchedDataSets[8],
			analysisCategory: fetchedDataSets[9] && 
				sorter(fetchedDataSets[9], "analysisCategory_number"),

			macro: fetchedDataSets[10] && 
				sorter(fetchedDataSets[10], "macro_number"),
			
			function: fetchedDataSets[11] && fetchedDataSets[11],
			paymentMethod: fetchedDataSets[12] && 
				sorter(fetchedDataSets[12], "paymentMethod_number"),
				
			priceband: fetchedDataSets[13] && sorter(fetchedDataSets[13], "priceband_number"),
			
			modifier: fetchedDataSets[14] && 
				sorter(fetchedDataSets[14], "modifier_number"),
			
			users: fetchedDataSets[15] &&
				sorter(fetchedDataSets[15], "user_number"),
				
			userTypes: fetchedDataSets[16] && 
				sorter(fetchedDataSets[16], "userType_number"),
			
			policies: fetchedDataSets[17] &&
				sorter(fetchedDataSets[17], "policy_number"),
			
			history: fetchedDataSets[4] && sorterPlu(fetchedDataSets[4], "plu_number").filter(
				(item) => item.plu_isDeleted === 1
			),
		});
		setLoading(false);
	};

	const [siteFilterValue, setSiteFilterValue] = React.useState([]);

	const [priceBandFilterValue, setPriceBandFilterValue] = React.useState([]);

	const priceBandColors = [
		{ priceband_number: 1, color: "#e6efef" },
		{ priceband_number: 2, color: "#96979933" },
		{ priceband_number: 3, color: "#d4f1f9" },
		{ priceband_number: 4, color: "#028A984D" },
		{ priceband_number: 5, color: "#0294784D" },
		{ priceband_number: 6, color: "#2C9EDC66" },
		{ priceband_number: 7, color: "#F9F9F9" },
		{ priceband_number: 8, color: "#2A9EC533" },
		{ priceband_number: 9, color: "#5E462D4D" },
		{ priceband_number: 10, color: "#54595F4D" },
	];

	return (
		<DataGridContext.Provider
			value={{
				data,
				setData,
				setNewData,
				// snackbarMessage,
				// setSnackbarMessage,
				// snackbarOpen,
				// setSnackbarOpen,
				loading,
				variant,
				setVariant,
				isRetail,
				setIsRetail,
				// snackbar,
				// setSnackbar,
				siteFilterValue,
				setSiteFilterValue,
				priceBandColors,
				priceBandFilterValue,
				setPriceBandFilterValue,
				showDeleted, setShowDeleted,
			}}
		>
			{props.children}
		</DataGridContext.Provider>
	);
};

export { DataGridContext, DataGridContextProvider };


