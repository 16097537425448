import React from "react";
import {
	Card,
	CardContent,
	Divider,
	Box,
	Typography,
	TextField,
	FormControlLabel,
	Checkbox,
	Button,
	Grid,
	Select,
	RadioGroup,
	Radio,
	FormControl,
	MenuItem,
	InputLabel,
} from "@mui/material";

export default function OptionSelect({
	item,
	addButton,
	label,
	option,
	defaultoption,
	options,
	placeholder,
	fullWidth,
	width,
	onChange,
	value,
	type,
	mt,
	mb,
	disabled,
	onClickAddButton,
	open,
	setOpen,
	multiple,
	shrink
}) {
	if (!options) return;
	return (
		<FormControl sx={{ width: width, mb: mb }}>
			<InputLabel shrink={shrink ? shrink : true} id="label">
				{label}
			</InputLabel>
			<Select
				className={
					type === "layout"
						? "layoutSelect"
						: type === "variant"
						? "variantSelect"
						: null
				}
				labelId="label"
				value={
					value === undefined || value === null || options?.length === 0
						? ""
						: type === 'group' ? options.find((c)=> c.group === value.group):type === 'layout' ? options.find((c)=> c.col === value.col && c.row === value.row):value
				}
				onChange={onChange}
				
				// defaultValue={defaultoption || ""}
				multiple={multiple}
				// defaultValue=""
				open={addButton && open}
				onClose={() => addButton && setOpen(false)}
				onOpen={() => addButton && setOpen(true)}
				displayEmpty
				notched={shrink ? shrink : true}
				// placeholder={placeholder}
				// sx={{ width: width, mb: mb }}
				disabled={disabled}
				size="small"
				label={label}
				renderValue={(o) => {
					// if (o.length === 0) {
					// 	return placeholder;
					// }
					return type === "PLU"
						? o.name
						: type === "list"
						? o.no
						: type === "area"
						? o.displayName
						: type === "number"
						? o.list_number + " - " + o.list_name
						: type === "layout"
						? o.value
						: type === "page"
						? o.number
						: type === "buttonType"
						? o.name
						: type === "Navigate"
						? o.name
						: type === "Function"
						? o.name
						: type === "group"
						? o.group
						: type === "modifier"
						? o
						: type === "site"
						? o.site_name
						: type === "tag"
						? o.tag_name
						: type === "fontSize"
						? o.name
						: type === "staydown"
						? o
						: type === "list_number"
						? o.list_number
						: type === "list_name"
						? o.list_name
						: type === "variant"
						? o.list_name
						: type === "modifierNewItemForm"
						? o.name
						: type === "priceBand"
						? o.priceband_name
						: type === "salesGroup"
						? o.salesGroup_name
						: o.value;
				}}
				MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
			>
				{addButton && (
					<div>
						<Button
							fullWidth
							sx={{ justifyContent: "flex-start" }}
							onClick={onClickAddButton}
						>
							+ ADD NEW
						</Button>
					</div>
				)}
				{options?.map((option, index) => (
					<MenuItem
						key={index}
						value={
							type === "Variant"
								? options.find((list) => list.uid === value.uid)
								: option
						}
					>
						{type === "align"
							? option.value
							: type === "PLU"
							? option.plu_name
							: type === "list"
							? option.no
							: type === "area"
							? option.displayName
							: type === "number"
							? option.list_number + " - " + option.list_name
							: type === "layout"
							? option.value
							: type === "page"
							? option.number
							: type === "buttonType"
							? option.name
							: type === "Navigate"
							? option.name
							: type === "Function"
							? option.name
							: type === "group"
							? option.group
							: type === "modifier"
							? option
							: type === "site"
							? option.site_name
							: type === "tag"
							? option.tag_name
							: type === "staydown"
							? option
							: type === "list_number"
							? option.list_number
							: type === "list_name"
							? option.list_name
							: type === "variant"
							? option.list_name
							: type === "modifierNewItemForm"
							? option.name
							: type === "priceBand"
							? option.priceband_name
							: type === "salesGroup"
							? option.salesGroup_name
							: option.name}
					</MenuItem>
				))}
				{options.length === 0 && <MenuItem>No Options</MenuItem>}
			</Select>
		</FormControl>
	);
}
