import React from "react";
import EditOffIcon from "@mui/icons-material/EditOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HistoryIcon from "@mui/icons-material/History";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
	GridRowModes,
	DataGrid,
	GridActionsCellItem,
	GridRowEditStopReasons,
	useGridApiRef,
	useGridSelector,
	gridPageCountSelector,
	GridFooterContainer,
	GridPagination,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AccessControl, { checkPermissions } from "../../AccessControl";
import { DataGridContext } from "../../../context/DataGridContext";
import { API_ENDPOINTS, PAGE_TABLE_MAPPING } from "../../../API/apiConfig";
import { Colours } from "../../../assets/global/Theme-variable";
import {
	CustomNoRowsOverlay,
	CustomOverlay,
	appearsOn,
	computeMutation,
	getNestedValue,
	modiferDataColumns,
} from "../utils";
import { makeStyles } from "@mui/styles";
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Snackbar,
	Typography,
	debounce,
} from "@mui/material";
import CustomColumnMenu from "../data grid files/CustomColumnMenu";
import MuiPagination from "@mui/material/Pagination";
import GridModal from "./components/Modal";
import CustomToolbar from "./components/Toolbar";
import ConfirmBox from "../data grid files/ConfirmBox";
import { useLocation, useNavigate } from "react-router-dom";
import HistoryModal from "./components/HistoryModal";
import GridTooltip from "./components/GridTooltip";
import { useAppContext } from "../../../appContext";
import UpdateIcon from '@mui/icons-material/Update';
import { useUserState } from "../../../context/UserContext";


// import whyDidYouRender from "@welldone-software/why-did-you-render";
// import { useWhatChanged } from '@simbathesailor/use-what-changed';
import {useRowsState, useRowStateUpdater } from "../../../context/RowsContext";
import { getAllRows, getRows } from "../DataGridUtils";
import Spinner from "../../../components/Spinner/Spinner";
import SnackbarContext from "../../../context/SnackbarContext";
import { toggleSnackbarOpen } from "../../../redux/actions/snackbarActions";
import { useDispatch } from "react-redux";

// whyDidYouRender(React, {
// //   onlyLogs: true,
//   titleColor: "green",
//   diffNameColor: "darkturquoise"
// });


export default function DataGridRefactor({
	setMacroModalVisible,
	modalVisible,
	type,
	url,
	setRowID,
	contextName,
	gridList,
	table,
	setModalConfig,
	modalConfig,
	setModalVisible,
}) {
	const { getAccessTokenSilently } = useAuth0();
	const [rowModesModel, setRowModesModel] = React.useState({});
	const ref = useGridApiRef({});
	const historyApiRef = useGridApiRef({});
const dispatch = useDispatch()
	const getVisibleColumns = () => {
		const visCol = {};
		for (let i = 0; i < gridList.columns.length; i++) {
			const field = gridList.columns[i].field;
			const visible = gridList.columns[i].visible;
			Object.assign(visCol, { [field]: visible });
		}
		return visCol;
	};
	const columns = getVisibleColumns();
	const [columnVisibilityModel, setColumnVisibilityModel] =
		React.useState(columns);
	// const [rows, setRows] = React.useState([])
	const rows = useRowsState();
	const setRows = useRowStateUpdater()
	// const {snackbar, setSnackbar} = React.useContext(SnackbarContext)
	const [searchValue, setSearchValue] = React.useState("");
	const [open, setOpen] = React.useState({
		status: false,
		id: null,
		msg: null,
		lists: [],
	});
	const { setState, state } = useAppContext();
	const [openDialog, setOpenDialog] = React.useState(false);
	const [historyModalOpen, setHistoryModalOpen] = React.useState({
		open: false,
		row: "",
	});
const [isSaving,setIsSaving] = React.useState(false)
	// const handleCloseSnackbar = () => setSnackbar(null);
	const {
		data,
		setData,
		variant,
		setVariant,
		siteFilterValue,
		priceBandFilterValue,
		priceBandColors,
		// userPermission,
	} = React.useContext(DataGridContext);
	const userPermission = useUserState();
// const[rowLoading,setRowLoading] = React.useState(true)
	const [showDeleted, setShowDeleted] = React.useState(false);

	const navigate = useNavigate();

	const useStyles = makeStyles(() => ({
		headerWrap: {
			whiteSpace: "break-spaces",
			wordWrap: "break-word",
			width: "900px", // adjust this value as needed
		},
	}));
	const classes = useStyles();
	const updateSearchValue = React.useMemo(() => {
		return debounce((newValue) => {
			ref.current.setQuickFilterValues(
				newValue.split(" ").filter((word) => word !== "")
			);
		}, 50);
	}, [ref]);

	const customFilterModel = useLocation();

	function handleSearchValueChange(event) {
		const newValue = event.target.value;
		setSearchValue(newValue);
		updateSearchValue(newValue);
	}

	function handleClearSearchValueChange(event) {
		setSearchValue('');
		updateSearchValue('');
	}

	const [filterModel, setFilterModel] = React.useState(
		customFilterModel?.state?.customFilterModel
			? customFilterModel?.state?.customFilterModel
			: {
					items: [
					],
			  }
	);

	React.useEffect(() => {
		// console.log('ue')
		if(showDeleted === false) {
		
			setRows(getRows(gridList, data, contextName,table,type))
		} else {	

			setRows(getAllRows(gridList, data, contextName,table,type))
		}
		// setTimeout(() => {
		// 	setRowLoading(false)
		// }, 4000);
	}, [gridList.gridPage, contextName, data,showDeleted,gridList, setRows,table,type]);


	const handleRowEditStop = (params, event) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const renderMultiDeleteDialog = (id) => {
		let variants = []
let selectedRows = []
if(gridList.gridPage === 'screens') {
	selectedRows = rows?.filter((row) =>
			selectedRowsData?.includes(parseInt(row?.uid))
		);
		selectedRows?.forEach((screen) => {
	variants.push([...data.screens?.filter((i) =>
		parseInt(i?.list_number) === parseInt(screen?.list_number) && i?.list_isDeleted === 0 && i?.list_variant !== 0
	)])
})
	}

	if(gridList.gridPage === 'userTypes') {
		selectedRows = rows?.filter((row) =>
		   selectedRowsData?.includes(parseInt(row?.uid))
	   );
	   selectedRows?.forEach((screen) => {
   variants.push([...data.users?.filter((i) =>
	   parseInt(i?.user_details?.userType) === parseInt(screen?.userType_ref) && i?.user_isDeleted === 0 
   )])
})
   }





		return (
			<Dialog
				open={!!openDialog}
				// fullWidth
				maxWidth="sm"
				scroll="body"
				onClose={() => setOpenDialog(!openDialog)}
				onBackdropClick={() => setOpenDialog(!openDialog)}
			>
				<DialogContent sx={{ px: 4, py: 4, position: "relative" }}>
					<IconButton
						size="medium"
						onClick={() => setOpenDialog(!openDialog)}
						sx={{ position: "absolute", right: "1rem", top: "1rem" }}
					>
						X
					</IconButton>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Box
								sx={{
									// mb: 3,
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
								}}
							>
								<Typography sx={{ mb: 1 }} variant="h2">
									Delete Rows
								</Typography>

								<Typography variant="body1">
									{gridList.gridPage === 'screens' ? 'Are you sure you want to delete the selected screen(s)? Deleting these screens will also delete their associated variants:' : gridList.gridPage === 'userTypes' ? 'Are you sure you want to delete the selected userType(s)? Deleting this user types may cause issues for the following users:' :'Are you sure you want to delete the selected row(s)?'}
								</Typography>
								{(gridList.gridPage === 'screens' || gridList.gridPage === 'userTypes') && variants?.length > 0 && 
   <List
   sx={{
	 width: '100%',
	 maxWidth: 360,
	 bgcolor: 'background.paper',
	 position: 'relative',
	 overflow: 'auto',
	 maxHeight: 300,
	 '& ul': { padding: 0 },
   }}
   subheader={<li />}
 >								{selectedRows?.map((screen, i) => 
								<>
								 <li key={`section-${screen.uid}`}>
          <ul>
          {variants[i].length > 0 &&  <ListSubheader>{screen.list_name ? screen.list_name : screen?.userType_name ? screen.userType_name : null}</ListSubheader>}
	
									{variants[i]?.map((i) => (
  <ListItem sx={{ p:0}}  key={`item-${screen.list_number ? screen.list_number : screen.user_number}-${i}`}>
  <ListItemText sx={{fontSize: 8,}} inset primary={i.list_name ? i.list_name : i.user_name} />
</ListItem>
))}
</ul>
</li>										 
								</>)}
								</List>}
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "1rem",
							}}
						>
							<Button
								onClick={() => setOpenDialog(!openDialog)}
								size="medium"
								variant="outlined"
							>
								Cancel
							</Button>
							<Button
								onClick={handleMultiDeleteConfirmation}
								size="medium"
								variant="contained"
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		);
	};

	const renderConfirmDialog = (id) => {
		if (!promiseArguments) {
			return null;
		}
		const { newRow, oldRow } = promiseArguments;

		const handleEntered = () => {
			// The `autoFocus` is not used because, if used, the same Enter that saves
			// the cell triggers "No". Instead, we manually focus the "No" button once
			// the dialog is fully open.
			// noButtonRef.current?.focus();
		};

		return (
			<Dialog
				maxWidth="xs"
				TransitionProps={{ onEntered: handleEntered }}
				open={!!promiseArguments}
			>
				<DialogTitle>Are you sure?</DialogTitle>
				<DialogContent
					dividers
				>{`Pressing 'Yes' will reinstate this row.`}</DialogContent>
				<DialogActions>
					<Button ref={noButtonRef} onClick={handleNo}>
						No
					</Button>
					<Button onClick={handleYes}>Yes</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const processRevert = React.useCallback(
		(newRow, oldRow) =>
			new Promise((resolve, reject) => {
				const mutation = computeMutation(newRow, oldRow);
				if (mutation) {
					setPromiseArguments({ resolve, reject, newRow, oldRow });
				} else {
					resolve(oldRow); 
				}
			}),
		[]
	);

	const handleSaveClick = (id) => () => {
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.View },
		}));
	};
	const noButtonRef = React.useRef(null);
	const [promiseArguments, setPromiseArguments] = React.useState(null);

	const handleNo = () => {
		const { oldRow, resolve } = promiseArguments;
		resolve(oldRow);
		setPromiseArguments(null);
	};

	const handleYes = async () => {
		const { newRow, oldRow, reject, resolve } = promiseArguments;
		try {
			const response = await handleRevertItem(newRow);
			resolve(response);
			setPromiseArguments(null);
		} catch (error) {
			reject(oldRow);
			setPromiseArguments(null);
		}
	};

	const handleRevertItem = async (id) => {
		const updatedRows = [...data[contextName]];
		const updatedRow = updatedRows?.find(
			(row) => parseInt(row?.[`${table}_uid`]) === parseInt(id)
		);
		updatedRow[`${table}_isDeleted`] = 0;

		let postStatus = 0;

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: [updatedRow],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.post(
				`${API_ENDPOINTS[table]}`,
				newRowData,
				config
			)
			.then(function (response) {
				postStatus = response?.status;
				console.log(response.data[table]);
				const newRows = [...data[contextName]]
				const newRow = response.data[table][0];
				newRow.uid = response.data[table][0][`${table}_uid`]
				const index = [...data[contextName]].findIndex((i) => parseInt(i[`${table}_uid`]) === parseInt(id));
				newRows[index] = newRow;
				setData({ ...data, [contextName]: newRows });
				if (postStatus === 200) {
					dispatch(toggleSnackbarOpen({
						children: "successfully reinstated",
						severity: "success",
						open: true
					}));
				}
			})
			.catch(function (error) {
				console.log(error)
				postStatus = error?.response?.status;

				dispatch(toggleSnackbarOpen({
					children: `ERROR couldn't save data back to server. ${error?.response?.statusText}.`,
					severity: "error",
					open: true
				}));
			});
	};

	const handleEditClick = React.useCallback(
		(params, event) => {
			const permitted = checkPermissions(userPermission, {
	page: gridList.gridPage,
					action: "edit",
					strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' ||  gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
				
			});
			if (
				permitted === true &&
				params?.row?.row?.user_number !== userPermission?.user_ref && 
			 params?.row?.row?.userType_number !== userPermission?.user_type
			) {
				if (
					event &&
					params &&
					params.row !== undefined &&
					params?.row?.row?.tag_details?.isEditable !== false
				) {
					if (
						typeof gridList.modalEdit === "undefined" ||
						gridList.modalEdit === false
					) {				
						setRowModesModel(
						
							{
								[params.id]: { mode: GridRowModes.Edit },
							}
						);
					} else {
						if (params.id) {
							setModalConfig({
								focusField: "",
								editRow: params.row,
								timestamp: new Date(),
								isNew: false,
								// open: true,
							});
							setModalVisible(true);
						}
					}
				}
			}
		},
		[rowModesModel, setRowModesModel, gridList]
	);

	const openDelete = (row) => () => {
		const ids = [row.id]
		const appears = appearsOn(data.screens, row.row.plu_number);
		let variants
			if(gridList.gridPage === 'screens') {
				variants = data.screens?.filter((i) =>
					parseInt(i?.list_number) === parseInt(row?.row?.list_number) && i?.list_isDeleted === 0 && i?.list_variant !== 0
				)
			 variants.map((i) => ids.push(parseInt(i?.list_uid)))
			}



const users = data?.users?.filter((user) => {
return parseInt(user?.user_details?.userType) === parseInt(row?.row?.userType_ref)
})

		setOpen({
			status: true,
			id: ids,
			msg:
				gridList.gridPage === "items" && appears.length > 0
					? `Deleting this item will affect buttons on the following screens:`
					: gridList.gridPage === "screens" && variants.length > 0
					? `Deleting this screen will also delete it's associated variants:`: gridList.gridPage === "userTypes" && users.length > 0 ? 'Deleting this user type will affect the following users and may cause issues.': null,
			lists:
				gridList.gridPage === "items" && appears.length > 0 ? appears :gridList.gridPage === "screens" && variants.length > 0 ? variants : gridList.gridPage === "userTypes" && users.length > 0 ? users  :null,
		});
	};

	const handleDelete = async (id) => {
		let deletedRows = [...data[contextName]]?.filter((row) =>
			id.includes(parseInt(row?.uid))
		);
		setOpen({ status: false, id: "", msg: null, lists: [] });

const table = PAGE_TABLE_MAPPING[gridList.gridPage]; 
if(variant !== undefined && deletedRows.find((row)=> row.uid === variant?.uid)){
	setVariant((draft)=> {draft.list_isDeleted = 1})
	deletedRows.filter((row)=> row.uid !== variant?.uid).forEach((row) => {
		row[table + "_isDeleted"] = 1;
	})
} else {
		deletedRows.forEach((row) => {
			row[table + "_isDeleted"] = 1;
		});
	}
		let postStatus = 0;

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: deletedRows,
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		axios
			.post(
				`${API_ENDPOINTS[table]}`, 
				newRowData,
				config
			)
			.then(function (response) {
				postStatus = response?.status;
				if (postStatus === 200) {
					dispatch(toggleSnackbarOpen({
						message: `Successfully deleted the selected row${
							id.length === 1 ? "" : "s"
						}`,
						severity: "success",
						duration: 4000,
						open: true

					}));
					setSelectedRowsData([])
setIsSaving(false)
					}
				
				
				setVariant()
				setTimeout(()=> { 
				if(showDeleted === false) {
					setRows(getRows(gridList, data, contextName,table,  type))
				} else {			setRows(getAllRows(gridList, data, contextName,table, type))
				}
			},[1000])

			})
			.catch(function (error) {
				console.log(error, postStatus);
				dispatch(toggleSnackbarOpen({
					message: `Couldn't delete requested row(s), please try again`,
					severity: "error",
					duration: 8000,
					open: true

				}));
setVariant()
			deletedRows.forEach((row) => {
				row[table + "_isDeleted"] = 0;
			});
			});
	};

	const handleDeleteClick = (id) => () => {
		handleDelete(id);
	};

	const handleCancelClick = (id) => () => {
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		}));

	
		const editedRow = rows.find((row) => parseInt(row.uid) === id);
		if (editedRow?.isNew) {
			setRows(rows.filter((row) => parseInt(row.uid) !== id));
		}
	};

	const processRowUpdate = async (newRow, originalRow, isNew) => {
		let updatedRow = { ...newRow, isNew: false };
		const collapseFieldData = [];
		let fieldLevel0 = "";
		let fieldLevel1 = "";
		Object.keys(updatedRow).forEach(function (key) {
			if (key.substring(0, 14) === "expandedfield:") {
				// find the field name
				const findComma = key.search(",");
				const fieldName = key.substring(14, findComma);
				// extract the source field
				const fieldLevelSpliter = fieldName.search("/");
				fieldLevel0 = fieldName.substring(0, fieldLevelSpliter);
				fieldLevel1 = fieldName.substring(fieldLevelSpliter + 1);
				// check the field has a value (i.e. not undefined)
				if (typeof updatedRow[key] !== "undefined") {
					let modValue = null;
					switch (typeof updatedRow[key]) {
						case "number":
							modValue = updatedRow[key];
							break;
						case "string":
							modValue = JSON.stringify(parseFloat(updatedRow[key]));
							break;
						default:
							modValue = null;
					}
					const fieldValue =
						"{" +
						key.substring(findComma + 1) +
						',"modifier":' +
						modValue +
						"}";

					if (
						modValue !== "null" &&
						modValue !== null &&
						typeof modValue !== "undefined"
					) {
						collapseFieldData.push(JSON.parse(fieldValue));
					}
				}
			}
		});

		if (fieldLevel0 !== "" && fieldLevel1 !== "") {
			updatedRow[fieldLevel0][fieldLevel1] = collapseFieldData;
		}

		const token = await getAccessTokenSilently();

		const newRowData = {
			updates: Array.isArray(newRow) ? newRow : [newRow],
		};

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.post(`${API_ENDPOINTS[table]}/?debug=true`, newRowData, config)
			.then(function (response) {
				if (response.status === 200) {
					let updatedData;
					if (isNew === true) {
						updatedRow = response?.data[table][0];
						updatedData = {
							...data,
							[contextName]: [...data[contextName], updatedRow],
						};
						
					} else {
						updatedData = {
							...data,
							[contextName]: data[contextName].map((row) =>
								row[`${table}_uid`] === updatedRow[`${table}_uid`]
									? updatedRow
									: row
							),
						};
					}
					setData(updatedData);
		setTimeout(()=> { 
			dispatch(toggleSnackbarOpen({
				message: "successfully saved",
				severity: "success",
				open: true

			}));
			setModalVisible(false)
			setIsSaving(false)
		},[2000])
				} else {
					dispatch(toggleSnackbarOpen({
						message: "Error, couldn't save data",
						severity: "error",
						open: true

					}));
				}
			})
			.catch(function (error) {
				console.log(error)
				dispatch(toggleSnackbarOpen({
					message: error?.response?.data?.error
						? error?.response?.data?.error + " - couldn't save data"
						: "Error - couldn't save data",
					severity: "error",
					open: true

				}));
			});

		return updatedRow;
	};


	const [selectedRowsData, setSelectedRowsData] = React.useState([]);

	const onRowsSelectionHandler = (ids) => {
		if(gridList.gridPage === 'screens') {
			let screens = []
			let variants = []
			let uids = []
			screens = rows?.filter((row) =>
				ids?.includes(parseInt(row?.uid))
			);
	screens?.forEach((screen) => {
		variants.push([...data.screens?.filter((i) =>
			parseInt(i?.list_number) === parseInt(screen?.list_number) && i?.list_isDeleted === 0 && i?.list_variant !== 0
		)])
	})
	variants?.map((variant)=> variant.map((v)=>  uids.push(parseInt(v?.list_uid))))
	ids = [...ids, ...uids]

		} 
		setSelectedRowsData(ids);
		
	};

	const handleNavigate = (item) => () => {
		if (gridList.gridPage === "screens") {
			setVariant()
			navigate("/designer", {
				state: { item: item.row },
			});
		} else if (gridList.gridPage === "items") {
			console.log(item);
			navigate(`/items/${item.row.plu_number}`, {
				state: { item: item.row },
			});
		} else if (gridList.gridPage === "sites") {
			let id = item.id;

			navigate(`/sites/${id}`, {
				state: { item: item.row },
			});
		}
	};

	//
	const dataColumnsBuilder = [];
	for (let i = 0; i < gridList?.columns?.length; i++) {
		if (gridList.columns[i].visible === true) {
			if (gridList.columns[i].type === "priceModifier") {
				const newColumns = modiferDataColumns(
					gridList.columns[i],
					rows,
					data,
					classes,
					ref,
					priceBandColors,
					siteFilterValue,
					priceBandFilterValue,
					priceBandFilterValue
				);
				if (newColumns.length > 0) {
					dataColumnsBuilder.push(...newColumns);
				}
			} else {
				dataColumnsBuilder.push(gridList.columns[i]);
			}
		}
	}

	dataColumnsBuilder.push({
		field: "Actions",
		hideable: false,
		type: "actions",
		headerName: "Actions",
		flex: gridList.gridPage !== "pricing" && 1,
		// width: 50,
		// minWidth: gridList.gridPage === "pricing" && 100,
		cellClassName: "actions",
		allowNavigate: gridList.allowNavigate,
		getActions: (row) => {
			const isRowInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

			const key = Object.keys(rowModesModel)[0];
			const isInEditMode = rowModesModel[key]?.mode === GridRowModes.Edit;
			if (row?.row[`${table}_isDeleted`] === 1) {
				return 	[actionOptions(row, gridList, "History")];
			}
			if (row?.row?.user_number === userPermission?.user_ref || row?.row?.userType_number === userPermission?.user_type){
				return [actionOptions(row, gridList, "NoEdit")];
			} 
			if (isRowInEditMode) {
				return [
					actionOptions(row, gridList, "Save"),
					actionOptions(row, gridList, "Cancel"),
				];
			}
			if (gridList.allowNavigate !== true) {
				if (
					typeof gridList.rowEditingField === "undefined" ||
					getNestedValue(row.row, gridList.rowEditingField)
				) {
					if (isInEditMode) {
						return [actionOptions(row, gridList, "Hidden")];
					}
					return [
						actionOptions(row, gridList, "Edit"),
						actionOptions(row, gridList, "Delete"),
						actionOptions(row, gridList, "History"),
					];
				} else {
					return [actionOptions(row, gridList, "NoEdit")];
				}
			} else if (isInEditMode) {
				return [actionOptions(row, gridList, "Hidden")];
			}
			if (
				gridList.gridPage === "screens" &&
				(row?.row?.list_details?.ignoreThisList === true ||
					row?.row?.list_details?.buttons?.some((x) => x.functionType === 13))
			) {
				return [actionOptions(row, gridList, "Hidden")];
			} else
				return [
					actionOptions(row, gridList, "Edit"),
					actionOptions(row, gridList, "Delete"),
					actionOptions(row, gridList, "History"),
					actionOptions(row, gridList, "More"),
				];
		},
	});

	const actionOptions = (row, gridList, option) => {
		switch (option) {
			case "Edit":
				return (
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: gridList.gridPage,
							action: "edit",
							// strict: false,
							strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
						}}
						renderNoAccess={() => <></>}
					>
						<GridTooltip label="Edit">
							
							<EditIcon
								fontSize="small"
								
								className="tableControl"
								onClick={(event) => {
									event.stopPropagation(); // Prevent the row click event from firing
									handleEditClick({ id: row.id, row: row }, event);
								}}
							/>
						</GridTooltip>
					</AccessControl>
				);
				break;
			case "Delete":
				return (
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: gridList.gridPage,
							action: "delete",
							strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'permissions' || gridList.gridPage === 'configuration'  || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
						}}
						renderNoAccess={() => <></>}
					>
						<GridTooltip label="Delete">
							<DeleteIcon
								fontSize="small"
							
								className="tableControl"
								onClick={openDelete(row)}
							/>
						</GridTooltip>
					</AccessControl>
				);
				break;
			case "More":
				return (
					// <GridActionsCellItem
					// 	icon={
					// 		<>
					// 			{/* <LightTooltip title="More"> */}
					// 			<ArrowForwardIcon fontSize="inherit" />
					// 			{/* </LightTooltip> */}
					// 		</>
					// 	}
					// 	label="Go"
					// 	// to do
					// 	onClick={handleNavigate(row)}
					// 	color="inherit"
					// 	className="tableControl"
					// />
					<GridTooltip label="More">
						<ArrowForwardIcon
							fontSize="small"
							
							className="tableControl"
							onClick={handleNavigate(row)}
						/>
					</GridTooltip>
				);
				break;
			case "Cancel":
				return (
					<GridActionsCellItem
						icon={
							<>
								{/* <LightTooltip title="Cancel"> */}
								<CancelIcon fontSize="inherit" />
								{/* </LightTooltip> */}
							</>
						}
						label="Cancel"
						//className="textPrimary"
						// className="tableControl"
						onClick={handleCancelClick(row.id)}
						color="inherit"
					/>
				);
				break;
			case "Save":
				return (
					<GridActionsCellItem
						icon={
							<>
								{/* <LightTooltip title="Save"> */}
								<SaveIcon fontSize="inherit" />
								{/* </LightTooltip> */}
							</>
						}
						label="Save"
						onClick={handleSaveClick(row.id)}
						// className="tableControl"
					/>
				);
				break;
			case "NoEdit":
				return (
					<GridActionsCellItem
						icon={
				
							<EditOffIcon fontSize="inherit" />
					
						}
						label="Uneditable"
						color="inherit"
						className="tableControl"
					/>
				);
				break;
			case "Reinstate":
				return (
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: gridList.gridPage,
							// allow: false,
							action: "undelete",
							strict: true,
						}}
						renderNoAccess={() => <></>}
					>
		
						<GridTooltip label="Reinstate">
							<UpdateIcon
								fontSize="small"
								
								className="tableControl"
								onClick={(event) => {
									event.stopPropagation();
									processRevert(row.id);
								}}
							/>
						</GridTooltip>
					</AccessControl>
				);
				break;
			case "History":
				return (
					<GridTooltip label="History">
						<HistoryIcon
							fontSize="small"
						
							className="tableControl"
							onClick={(event) => {
								event.stopPropagation();
								setHistoryModalOpen({ open: true, row: row });
							}}
						/>
					</GridTooltip>
				);
				break;
			case "Hidden":
				return <></>;

			default:
		}
	};

	const handleMultiCheckBoxDelete = () => {
		setOpenDialog(true);
	};

	const handleMultiDeleteConfirmation = () => {
		handleDelete(selectedRowsData);
		setOpenDialog(false);
		// setSelectedRowsData([]);
	};


	const handleMultiCheckBoxSelect = () => {
	
		if (gridList.checkBoxDelete === true) {
			const displayButton = selectedRowsData.length === 0 ? false : true;
			return (
				<>
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: gridList.gridPage,
							// allow: false,
							action: "delete",
							strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'permissions' || gridList.gridPage === 'configuration'  || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
					
						}}
						renderNoAccess={() => <></>}
					>
						{selectedRowsData.length > 0 ? (
							<Button
								onClick={handleMultiCheckBoxDelete}
								disabled={!displayButton}
								startIcon={<CheckBoxIcon color={Colours.gsblue} size={30} />}
								size="medium"
							>
								Delete selected row
								{selectedRowsData.length === 1 ? "" : "s"}
							</Button>
						) : null}
					</AccessControl>
				</>
			);
		}
	};

	const CustomFooter = (props) => {
		const { paginationProps } = props;
		return (
			<GridFooterContainer>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box>{handleMultiCheckBoxSelect()}</Box>
					<Box>
						<GridPagination
							{...paginationProps}
							ActionsComponent={Pagination}
						/>
					</Box>
				</Box>
			</GridFooterContainer>
		);
	};

	const handleCellKeyDown = React.useCallback((params, event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			event.stopPropagation();
			setRowModesModel((oldModel) => ({
				...oldModel,
				[params.id]: { mode: GridRowModes.View },
			}));
		}
	}, []);

	const getTogglableColumns = (columns) => {
		return columns
			.filter(
				(column) =>
					column.field !== "tagExport" &&
					column.field !== "__check__" &&
					column.field !== "Actions" &&
					column.allowToggle !== false
			)
			.map((column) => column.field);
	};

	const toggleShowDeleted = (e) => {
		setShowDeleted(e.target.checked);
// if(e.target.checked === true) {
// 		setRows(getAllRows(gridList, data, contextName,table, type));
// } else {
// 	setRows(getRows(gridList, data, contextName,table, type));

// }
	};

	function Pagination({ page, onPageChange, className }) {
		const pageCount = useGridSelector(ref, gridPageCountSelector);

		return (
			<MuiPagination
				color="primary"
				className={className}
				count={pageCount}
				page={page + 1}
				onChange={(event, newPage) => {
					onPageChange(event, newPage - 1);
				}}
				size="small"
				shape="rounded"
			/>
		);
	}


	const autosizeOptions = {
		includeOutliers: true,
	  };


	return (
		<
		>
			{renderConfirmDialog()}
			{renderMultiDeleteDialog()}

			<ConfirmBox
				deleteFunction={handleDeleteClick(open?.id)} // send through the one ID as an array
				open={open}
				gridList={gridList}
				page={gridList.gridPage}
				closeDialog={() => setOpen({
					status: false,
					id: null,
					msg: null,
					lists: [],
				})}
			/>
			<GridModal
				modalConfig={modalConfig}
				gridList={gridList}
				setModalConfig={setModalConfig}
				modalVisible={modalVisible}
				defaultValues={modalConfig?.editRow?.row}
				setModalVisible={setModalVisible}
				processRowUpdate={processRowUpdate}
				contextName={contextName}
				table={table}
				isSaving={isSaving}
				setIsSaving={setIsSaving}
			/>

			<HistoryModal
				modalConfig={historyModalOpen}
				gridList={gridList}
				setModalConfig={setHistoryModalOpen}
				processRowUpdate={processRowUpdate}
				contextName={contextName}
				table={table}
				apiRef={historyApiRef}
			/>
			<DataGrid
				rows={rows}
				columns={dataColumnsBuilder}
	
				initialState={{
					pagination: { paginationModel: { pageSize: 100 } },
					columns: {
						columnVisibilityModel,
					},
				}}
				autosizeOptions={autosizeOptions}

				filterModel={filterModel}
				onFilterModelChange={(newFilterModel) => {
					setFilterModel(newFilterModel);
				}}
				// paginationMode="server"
				// getEstimatedRowHeight={() => 100}
autoPageSize={false}
				getRowHeight={() => "auto"}
				// pageSizeOptions={[5, 10, 25, 50]}
				getRowId={(row) =>  parseInt(row[`uid`])}
				sx={{
					// ...dataGridStyle,
					"& .firstRow": {
						// display: state.stepIndex === 4 ? null : "none",
						"& .tableControl": {
							display: state.stepIndex === 4 ? "unset" : "none",
						},
					},
				}}
				apiRef={ref}
				// disableRowSelectionOnClick
				showCellVerticalBorder
				showCellHorizontalBorder
				editMode="row"
				columnHeaderHeight={gridList.gridPage === "pricing" ? 90 : 56}
				rowModesModel={rowModesModel}
				// onRowModesModelChange={handleRowModesModelChange}
				onRowEditStop={handleRowEditStop}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={(newModel) =>
					setColumnVisibilityModel(newModel)
				}
				onCellKeyDown={handleCellKeyDown}
				density={"compact"}
				slots={{
					toolbar: CustomToolbar,
					noRowsOverlay: CustomNoRowsOverlay,
					noResultsOverlay: CustomOverlay,
					footer: CustomFooter,
					// loadingOverlay: 'skeleton',
					columnMenu: CustomColumnMenu,
				}}
				getRowClassName={(params) =>

					params?.row[`${table}_isDeleted`] &&
					(params?.row[`${table}_isDeleted`] === 1 ||
						params?.row[`${table}_isDeleted`] === "1")
						? "deletedRow"
						: params.indexRelativeToCurrentPage === 0
						? "firstRow"
						: ""
				}
				getCellClassName={(params) => {
					if (
						params.field === "printer_details.sites" ||
						params.field === "printer_details.site_name" ||
						params.field === "printer_details.site_ref"
					) {
						return "printerDetails";
					}
				}}
				slotProps={{
					toolbar: {
						data,
						modalVisible,
						setModalVisible,
						modalConfig,
						setModalConfig,
						setData,
						setRowModesModel,
						gridList,
						selectedRowsData,
						// setSnackbar,
						searchValue,
						handleSearchValueChange,
						handleClearSearchValueChange,
		
						table,
						contextName,
						showDeleted,
						filterModel,
						setFilterModel,
						toggleShowDeleted: toggleShowDeleted,
					},
					columnsPanel: {
						getTogglableColumns,
					},
					getActions: gridList,
					// handleCheckBoxDelete: handleMultiCheckBoxDelete,
					columnHeader: {
						className: classes.headerWrap,
					},
					loadingOverlay: {
						// variant: 'skeleton',
						// noRowsVariant: 'skeleton',
					  },
				
				}}
				checkboxSelection
				disableRowSelectionOnClick
				onRowDoubleClick={(params, event) => {
					event.stopPropagation();
					
					handleEditClick({ id: params.id, row: params }, event);
				
				}}
				isRowSelectable={(params) => { 
					if(params?.row?.user_number === userPermission?.user_ref || params?.row?.userType_number === userPermission?.user_type){
					return false
				}else {
					return true}}}
				onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
				processRowUpdate={(updatedRow, originalRow) => {
					if (updatedRow !== originalRow) {
						processRowUpdate(updatedRow, originalRow);
					}
					return updatedRow;
				}}
				experimentalFeatures={{ newEditingApi: true }}
				// loading={rowLoading}
			/>
		
		</>
	);
}
// DataGridRefactor.displayName = "DataGrid";
// DataGridRefactor.whyDidYouRender = true
