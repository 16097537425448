import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { DataGridContext } from "../context/DataGridContext";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { MyFallbackComponent } from "./FallBackComponent";
import { Card, CardActions, CardContent, Stack } from "@mui/material";
import { Colours } from "../assets/global/Theme-variable";
import StatCard from "../components/StatCard";
import { CiBeerMugFull } from "react-icons/ci";
import { RiGridFill } from "react-icons/ri";
import { BsGeoAlt, BsPeople } from "react-icons/bs";
import MaxsuiteLogo from "../assets/images/MaxsuiteLogo.png";
import MaxSuite from "../assets/images/MAXSuite.png";
import { FaEnvelope, FaFacebook } from "react-icons/fa6";
import { useMount } from "react-use";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";

import { Link, useNavigate } from "react-router-dom";
import IssueCard from "../components/IssueCard";
import LinkCard from "../components/LinkCard";
import FeedbackModal from "../components/FeedbackModal";
import {
	ContactPhone,
	HelpOutline,
	MenuBookOutlined,
	PhoneOutlined,
	ReviewsOutlined,
	WarningOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/styles";
import { motion } from "framer-motion";
import ContactModal from "../components/ContactModal";
import IssueModal from "../components/IssueModal";
import MailChipForm from "../components/MailChipForm";
import SocialIcon from "../components/SocialIcon";
import { BiGridAlt, BiGroup, BiMapPin, BiSolidDrink } from "react-icons/bi";
import { API_ENDPOINTS } from "../API/apiConfig";
import { useAppContext } from "../appContext";
import { NoPermissionDialog } from "../components/NoPermissionDialog";
import { useLoggedInUserState, useUserState, useUserUpdater } from "../context/UserContext";

const Img = styled("img")({
	margin: "auto",
	display: "block",
	objectFit: "contain",
	maxWidth: "100%",
	maxHeight: "100%",
	objectPosition: "center",
	overflow: "hidden",
	borderRadius: 2,
});

export default function Home() {
	const { getAccessTokenSilently, error } = useAuth0();
	const { data, noPermission, setNoPermission,loading } = useContext(DataGridContext);
	const navigate = useNavigate();
	const [modalVisible, setModalVisible] = React.useState(false);
	const [feedbackModalVisible, setFeedbackModalVisible] = React.useState(false);
	const [issueModalVisible, setIssueModalVisible] = React.useState(false);
	const loggedInUser= useLoggedInUserState();
	const userPermission = useUserState()
	function openWidget() {
		window.FreshworksWidget("open");
	}
	const getListIssues = (params, item) => {
		if( userPermission?.permissions?.length > 0) {
		const listsToCheck = data?.screens?.filter(
			(list) =>
				list.list_details.ignoreThisList !== true &&
				list.list_details.location !== "F3" &&
				list.list_isDeleted === 0 
				// && list.list_variant === 0
		);
		let value = listsToCheck?.filter((list) => {
			return list.list_details.buttons?.some((button) => {
				if (button?.functionType === 1 && button.mx !== 0 && button.my !== 0) {
					return !data?.function?.find(
						(item) => parseInt(item?.function_number) === button?.functionValue
					);
				} else if (
					button?.functionType === 12 &&
					button.mx !== 0 &&
					button.my !== 0
				) {
					return !data?.screens
						?.filter((i) => i.list_isDeleted === 0)
						.find(
							(item) => parseInt(item?.list_number) === button?.functionValue
						);
				} else if (
					button?.functionType === 21 &&
					button.mx !== 0 &&
					button.my !== 0
				) {
					return !data?.items
						?.filter((i) => i.plu_isDeleted === 0)
						.find(
							(item) => parseInt(item?.plu_number) === button?.functionValue
						);
				} else if (
					button?.functionType === 3 &&
					button.mx !== 0 &&
					button.my !== 0
				) {
					return !data?.modifier
						?.filter((i) => i.modifier_isDeleted === 0)
						.find(
							(item) =>
								parseInt(item?.modifier_number) === button?.functionValue
						);
				} else if (
					button?.functionType === 2 &&
					button.mx !== 0 &&
					button.my !== 0
				) {
					return !data?.paymentMethod
						?.filter((i) => i.paymentMethod_isDeleted === 0)
						.find(
							(item) =>
								parseInt(item.paymentMethod_number) === button?.functionValue
						);
				} else if (
					button?.functionType === 20 &&
					button.mx !== 0 &&
					button.my !== 0
				) {
					return !data?.macro
						?.filter((i) => i.macro_isDeleted === 0)
						.find(
							(item) => parseInt(item?.macro_number) === button?.functionValue
						);
				} else if (
					button?.functionType === 13 &&
					button.mx !== 0 &&
					button.my !== 0
				) {
					return !data?.presetServers?.find(
						(item) => item?.value === button?.functionValue
					);
				} else {
					return null;
				}
			});
		});
	
		return value;
	}
	};


	const getPLUIssues = (params, item) => {
		if( userPermission?.permissions?.length > 0) {
		let value = data?.items
			?.filter((i) => i.plu_isDeleted === 0)
			.filter((item) => {
				return !data?.categories
					?.filter((i) => i.salesGroup_isDeleted === 0)
					.find((cat) => {
						if (typeof item.plu_details.salesGroup !== "string") {
							return (
								cat?.salesGroup_number ===
								JSON.stringify(item?.plu_details?.salesGroup)
							);
						} else
							return cat?.salesGroup_number === item?.plu_details?.salesGroup;
					});
			});
		return value;
		}
	
	};

	// const getRecentlyChanged = (params, item) => {
	// 	const arr = []

	// 	data.forEach((set)=> set.filter((item)=> item.))
	// // data?.items
	// // 		?.filter((i) => i.plu_isDeleted === 0)
	// // 		.filter((item) => {
	// // 			return !data?.categories
	// // 				?.filter((i) => i.salesGroup_isDeleted === 0)
	// // 				.find((cat) => {
	// // 					if (typeof item.plu_details.salesGroup !== "string") {
	// // 						return (
	// // 							cat?.salesGroup_number ===
	// // 							JSON.stringify(item?.plu_details?.salesGroup)
	// // 						);
	// // 					} else
	// // 						return cat?.salesGroup_number === item?.plu_details?.salesGroup;
	// // 				});
	// // 		});
	// // 	return value;
	// };

	// const getSites = async () => {
	// 	const token = await getAccessTokenSilently();

	// 	const config = {
	// 		headers: { Authorization: `Bearer ${token}` },
	// 	};

	// 	const selectedSite = "";

	// 	axios
	// 		.get(`https://max-suite.gs-systems.co.uk/api/site/getAll`, config)
	// 		.then(function (response) {
	// 			console.log("res", response);
	// 		})
	// 		.catch(function (error) {
	// 			console.log("res", error);
	// 		});
	// };

	// useEffect(() => {
	// 	getSites();
	// }, []);

	const [generalIssues, setGeneralIssues] = React.useState([]);

	useEffect(() => {
		if(userPermission?.permissions?.length > 0) {
		getGeneralIssues();
		}
	}, []);

	const getGeneralIssues = async () => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.get(
				`${API_ENDPOINTS.getAllNotes}?dateTo=2024-11-21&dateFrom=2023-09-01`,
				config
			)
			.then(function (response) {
				// console.log("res", response);
				setGeneralIssues(response);
			})
			.catch(function (error) {
				console.log("res", error);
			});
	};

	// console.log(generalIssues);

	const stats = [
		{
			name:
				data?.items?.filter((i) => i.plu_isDeleted === 0).length === 1
					? "Item"
					: "Items",
			stat:
				data?.items !== null
					? data?.items?.filter((i) => i.plu_isDeleted === 0).length
					: null,
			url: "items",
			icon: BiSolidDrink,
			iconSize: 20,
		},
		{
			name:
				data?.screens?.filter((i) => i.list_isDeleted === 0).length === 1
					? "Screen"
					: "Screens",
			stat:
				data?.screens !== null
					? data?.screens?.filter((i) => i.list_isDeleted === 0).length
					: null,
			url: "screens",
			icon: BiGridAlt,
			iconSize: 20,
		},
		{
			name:
				data?.staff?.filter((i) => i.server_isDeleted === 0).length === 1
					? "Staff Member"
					: "Staff",
			stat:
				data?.staff !== null
					? data?.staff?.filter((i) => i.server_isDeleted === 0).length
					: null,
			url: "staff",
			icon: BiGroup,
			iconSize: 20,
		},
		{
			name:
				data?.sites?.filter((i) => i.site_isDeleted === 0).length === 1
					? "Site"
					: "Sites",
			stat:
				data?.sites !== null
					? data?.sites?.filter((i) => i.site_isDeleted === 0).length
					: null,
			url: "sites",
			icon: BiMapPin,
			iconSize: 20,
		},

		// { name: "tags", stat: data.tags.length, url: "tags" },
	];

	const links = [
		// {
		// 	name: "Feedback",
		// 	onClick: () => setFeedbackModalVisible(true),
		// 	icon: ReviewsOutlined,
		// 	iconSize: 30,
		// },
		// {
		// 	name: "MaxSuite",
		// 	url: "sites",
		// 	icon: BsGeoAlt,
		// 	iconSize: 25,
		// },
		// {
		// 	name: "Training",
		// 	onClick: () => navigate("/training"),
		// 	icon: MenuBookOutlined,
		// 	iconSize: 26,
		// },
		// { name: "tags", stat: data.tags.length, url: "tags" },
		{
			name: "Help",
			onClick: openWidget,
			icon: FaQuestion,
			iconSize: 25,
		},
		// {
		// 	name: "Contact",
		// 	onClick: () => setModalVisible(true),
		// 	icon: PhoneOutlined,
		// 	iconSize: 25,
		// },
		// {
		// 	name: "Report Issue",
		// 	onClick: () => setIssueModalVisible(true),
		// 	icon: WarningOutlined,
		// 	iconSize: 25,
		// },
	];

	const socials = [
		{
			name: "Facebook",
			url: "https://www.facebook.com/muchmorethanEPOS/",
			icon: FaFacebook,
			iconSize: 30,
		},
		{
			name: "LinkedIn",
			url: "https://www.linkedin.com/company/gs-systems/",
			icon: FaLinkedinIn,
			iconSize: 25,
		},

		{
			name: " Twitter",
			url: "https://twitter.com/gs_systems?lang=en-GB",
			icon: FaXTwitter,
			iconSize: 25,
		},
		{
			name: "Phone",
			url: "tel:+4401614835595",
			icon: FaPhone,
			iconSize: 25,
		},
		{
			name: "email",
			url: "mailto:support@gs-systems.co.uk",
			icon: FaEnvelope,
			iconSize: 25,
		},
	];

	let text;

	var today = new Date();
	var curHr = today.getHours();

	if (curHr < 12) {
		text = "Good morning";
	} else if (curHr < 18) {
		text = "Good afternoon";
	} else {
		text = "Good evening";
	}

	const year = today.getUTCFullYear();
	const {
		setState,
		state: { run },
	} = useAppContext();

	// const handleClickStart = () => {
	// 	setState({ run: true, tourActive: true });
	// };

	useMount(() => {
		if (loggedInUser?.logins_count === 1) {
			setState({ run: true, tourActive: true });
		}
	});

	if (error) {
		return <MyFallbackComponent error={error.message} />;
	} else
		return (
			<>
				<ContactModal
					modalVisible={modalVisible}
					setModalVisible={setModalVisible}
				/>
				<FeedbackModal
					modalVisible={feedbackModalVisible}
					setModalVisible={setFeedbackModalVisible}
				/>
				<IssueModal
					modalVisible={issueModalVisible}
					setModalVisible={setIssueModalVisible}
				/>
				{/* <NoPermissionDialog open={noPermission} setOpen={setNoPermission}/> */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "flex-start",
						maxWidth: "100%",
						flex: 1,
						height: "100%",
						overflow: "hidden",
						ml: 2,
						mr: 2,

						// pt: 1,
					}}
				>
					<Box sx={{ maxHeight: "10%", width: "100%" }}>
						{loggedInUser?.nickname ? (
							<Typography
								sx={{
									fontSize: 22,
									color: Colours.gsblue,
									mb: 2,
									// ml: 2,
									mt: 2,
									alignSelf: "flex-start",
								}}
								color="text.secondary"
							>
								{text}, {loggedInUser?.nickname}!
							</Typography>
						) : (
							<Skeleton
								animation="wave"
								sx={{ bgcolor: Colours.white, alignSelf: "flex-start" }}
							>
								<Typography
									sx={{
										fontSize: 22,
										color: Colours.gsblue,
										mb: 4,
										// ml: 2,
										mt: 2,
									}}
									color="text.secondary"
								>
									{text}, username!
								</Typography>
							</Skeleton>
						)}
					</Box>
					<Stack
						spacing={2}
						direction="row"
						sx={{ width: "100%", maxHeight: "20%", mb: 2, ml: 2, mr: 2 }}
					>
						{stats.map((stat, index) => (
							// Added in a key to resolve web console errors
							<StatCard key={index} stat={stat} />
						))}
					</Stack>
					<Stack
						spacing={2}
						direction="row"
						justifyContent="flex-start"
						sx={{ width: "100%", maxHeight: "70%", flex: 1, ml: 2, mr: 2 }}
					>
						<Stack
							justifyContent="flex-start"
							alignItems="flex-start"
							direction="column"
							spacing={2}
							sx={{
								width: "25%",
								margin: 2,
								padding: 1,
								// height: "100%",
								// backgroundColor: "#DFF2F8",
								// border: `0.5px solid ${Colours.gsgrey}`,
								borderRadius: 1,
								pb: 2,
								pt: 2,
							}}
						>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Typography
									sx={{
										fontSize: 16,
										fontWeight: 600,
										color: Colours.darkGrey,
										// ml: 2,
									}}
								>
									Item Issues
								</Typography>
								{userPermission?.permissions?.length === 0 ? (<></>):(
								<Button
									onClick={() =>
										navigate(`/items`, {
											state: {
												customFilterModel: {
													items: [
														{
															field: "plu_details.salesGroup",
															operator: "isEmpty",
															value: true,
														},
													],
												},
											},
										})
									}
								>
									View All
								</Button>)}
							</Box>
							{userPermission?.permissions?.length === 0 ? (<></>): (data?.items === null ||
							data?.paymentMethod === null ||
							data?.function === null ||
							data?.presetServers === null) && loading ? (
								<Skeleton
									variant="rectangular"
									width="100%"
									sx={{ bgcolor: Colours.white }}
								>
									<IssueCard />
								</Skeleton>
							) :!loading && getPLUIssues()?.length > 0  ? (
								<Box
									sx={{
										overflowY: "auto",
										overflowX: "hidden",
										flex: 1,
										width: "100%",
										"&::-webkit-scrollbar": {
											width: "6px",
										},
										"&::-webkit-scrollbar-track": {
											backgroundColor: "#f5f5f5",
										},
										"&::-webkit-scrollbar-thumb": {
											borderRadius: "10px",
											boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
											// backgroundColor: "#f5f5f5",
										},
									}}
								>
									{getPLUIssues()?.map((plu, index) => (
										// Added in a key to resolve web console errors
										<IssueCard key={`plu_issue_${index}`} plu={plu} />
									))}
								</Box>
							) : (
								<Typography variant="body2" sx={{ color: Colours.darkGrey }}>
									No issues found{" "}
								</Typography>
							)}
						</Stack>
						<Stack
							justifyContent="flex-start"
							alignItems="flex-start"
							direction="column"
							spacing={2}
							sx={{
								width: "25%",
								// margin: 2,
								padding: 1,
								// backgroundColor: "#DFF2F8",
								// border: `0.5px solid ${Colours.gsgrey}`,

								borderRadius: 1,
								pb: 2,
								pt: 2,
							}}
						>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Typography
									sx={{
										fontSize: 16,
										fontWeight: 600,
										color: Colours.darkGrey,
										// ml: 2,
									}}
								>
									Screen Issues
								</Typography>
								{userPermission?.permissions?.length === 0 ? (<></>):(
								<Button
									onClick={() =>
										navigate(`/screens`, {
											state: {
												customFilterModel: {
													items: [
														{
															field: "list_details.buttons",
															operator: "isEmpty",
															value: true,
														},
													],
												},
											},
										})
									}
								>
									View All
								</Button>)}
							</Box>

							{userPermission?.permissions?.length === 0 ? (<></>):(data?.items === null ||
							data?.paymentMethod === null ||
							data?.function === null ||
							data?.presetServers === null) && loading  ? (
								<Skeleton
									variant="rectangular"
									width="100%"
									sx={{ bgcolor: Colours.white }}
								>
									<IssueCard />
								</Skeleton>
							) : !loading && getListIssues()?.length > 0 ? (
								<Box
									sx={{
										overflowY: "auto",
										overflowX: "hidden",
										"&::-webkit-scrollbar": {
											width: "6px",
										},
										"&::-webkit-scrollbar-track": {
											backgroundColor: "#f5f5f5",
										},
										"&::-webkit-scrollbar-thumb": {
											borderRadius: "10px",
											boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
											// backgroundColor: "#f5f5f5",
										},
										flex: 1,
										width: "100%",
									}}
								>
									{getListIssues()?.map((list, index) => (
										<IssueCard key={`list_issue_${index}`} list={list} />
									))}
								</Box>
							) : (
								<Typography variant="body2" sx={{ color: Colours.darkGrey }}>
									No issues found
								</Typography>
							)}
						</Stack>
						<Box
							sx={{
								width: "25%",
								// margin: 2,
								// padding: 1,
								height: "100%",
								backgroundColor: "white",
								borderRadius: 1,
								pb: 2,
							}}
						>
								{/* <Typography
									sx={{
										fontSize: 16,
										fontWeight: 600,
										color: Colours.darkGrey,
										// ml: 2,
									}}
								>
									Recent Changes 
								</Typography>
								{getRecentlyChanged()?.map((list, index) => (
<Typography>{list}</Typography>								))} */}
							{/* <a
							target="_blank"
							rel="noopener noreferrer"
							href="https://max-suite.gs-systems.co.uk/"
						>
							<LogoIcon src={maxSuite} />
						</a> */}
						</Box>
						{/* <Box
							sx={{
								width: "25%",
								margin: 2,
								// padding: 1,
								backgroundColor: "#DFF2F8",
								borderRadius: 1,
							}}
						></Box> */}
						{/* <Box
							sx={{
								width: "25%",
								margin: 2,
								// padding: 1,
								backgroundColor: "#DFF2F8",
								borderRadius: 1,
							}}
						></Box> */}

						<Stack
							sx={{
								width: "25%",
								// margin: 2,
								// padding: 1,
								// backgroundColor: "#DFF2F8",
								// border: `0.5px solid ${Colours.gsgrey}`,

								// // borderRadius: 2,
								// pb: 2,
								// pt: 2,
								height: "100%",
							}}
							direction={"column"}
							// useFlexGap
							// flexWrap="wrap"
							// spacing={2}
						>
							<Grid
								container
								justifyContent="flex-start"
								rowSpacing={2}
								columnSpacing={2}
								columns={12}
								sx={{ mb: 2 }}
							>
								{links.map((link, index) => (
									// Added in a key to resolve web console errors
									<Grid item xs={6} sx={{ padding: 0 }} key={`link_${index}`}>
										<LinkCard link={link} />
									</Grid>
								))}
								<Grid item xs={6} key="maxSuiteCard">
									<Card
										sx={{
											aspectRatio: 1 / 1,
											backgroundColor: "#DFF2F8",
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
											// margin: 2,
											// padding: 1,
											borderRadius: 1,
											"&::before": {
												aspectRatio: 1 / 1,

												display: "flex",
												alignSelf: "stretch",
												flex: 1,
												padding: "100%",
											},
										}}
										component={motion.div}
										// react-dom.development.js:86 Warning: React does not recognize the `whileHover``whileTap` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `whilehover` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
										whilehover={{ scale: 1.1 }}
										whiletap={{ scale: 0.9 }}
										elevation={3}
									>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://max-suite.gs-systems.co.uk/"
										>
											<Img alt="Max Suite Logo" src={MaxSuite} />
										</a>
									</Card>
								</Grid>
							</Grid>
							<Grid
								container
								justifyContent="flex-start"
								rowSpacing={2}
								columnSpacing={2}
								columns={5}
								sx={{ mb: 2 }}
							>
								{socials.map((link, index) => (
									// Added in a key to resolve web console errors
									<Grid item xs={1} sx={{ padding: 0 }} key={`social_${index}`}>
										<SocialIcon link={link} />
									</Grid>
								))}
							</Grid>
							<MailChipForm />
						</Stack>
					</Stack>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flex: 1,
						}}
					>
						<Typography
							variant="body2"
							sx={{ color: Colours.gsblue, fontSize: 12 }}
						>
							Copyright GS Systems © {year}
						</Typography>
					</Box>
					{/* <Stack
						direction="row"
						alignItems="space-between"
						justifyContent="space-between"
						spacing={2}
						sx={{ height: "10vh", mb: 2 }}
					>
						{links.map((link) => (
							// <Grid item xs={6} sx={{ padding: 0 }}>
							<LinkCard link={link} />
							// </Grid>
						))}
						<Card
							sx={{
								aspectRatio: 2 / 1,
								backgroundColor: "#DFF2F8",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								// margin: 2,
								// padding: 1,
								borderRadius: 1,
								"&::before": {
									aspectRatio: 2 / 1,

									display: "flex",
									alignSelf: "stretch",
									flex: 1,
									padding: "100%",
								},
							}}
							component={motion.div}
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.9 }}
							elevation={3}
						>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://max-suite.gs-systems.co.uk/"
							>
								<Img alt="Max Suite Logo" src={MaxSuite} />
							</a>
						</Card>
						<MailChipForm />
					</Stack> */}
				</Box>
			</>
		);
}
