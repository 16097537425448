import React from "react";
import { motion } from "framer-motion";
import {
	DragOverlay,
} from "@dnd-kit/core";

import {
	SortableContext,	rectSwappingStrategy,
} from "@dnd-kit/sortable";
import GridContext from "../../../context/GridContext";
import { Box } from "@mui/material";
import { CursorContext } from "../../../components/CursorProvider";
import NewGridItem from "./NewGridItem";
import DragOverlayItem from "./DragOverlay";
import { Colours } from "../../../assets/global/Theme-variable";

export default function NewGridRefactor({
	handleOpen,
	setErrorMessage,
	disableSelection,
	colNo,
	rowNo,
	item,
	forbidDrag,
	setForbidDrag,
	open,
	setOpen,
	textAlign,
	selectedColour,
	selectedTextColour,
	openItem,
	activeId,
	draggingItem,
	editBar,
	errorMessage,
	showText,
	...props
}) {
	const { listArea, variant, page, copyStyles } = React.useContext(GridContext);
	const { onCursor } = React.useContext(CursorContext);


	const container = {
		hidden: { opacity: 0 },
		show: {
			opacity: 1,
		},
	};

	if (!variant?.list_details?.buttons) return;
	return (
		<>
			{variant?.list_details?.location === "F3" ? (
				<Box
					sx={{
						width: "100%",
						height: "100%",
						backgroundColor: Colours.gsgrey,
						gridRow:
							listArea.tillName === "L"
								? "1/6"
								: listArea.tillName === "F"
								? "5/11"
								: "1/11",
						gridColumn:
							listArea.tillName === "L"
								? "1/10"
								: listArea.tillName === "F"
								? "1/6"
								: "1/11",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						padding: 10,
					}}
				>
			
				</Box>
			) : (
				<>
					<SortableContext
						disabled={variant?.list_details?.location === "F3"}
						items={variant.list_details.buttons}
						strategy={rectSwappingStrategy}
					>
						<motion.div
							style={{
								gridRow:
									listArea.tillName === "L"
										? "1/6"
										: listArea.tillName === "F"
										? "5/11"
										: "1/11",
								gridColumn:
									listArea.tillName === "L"
										? "1/10"
										: listArea.tillName === "F"
										? "1/6"
										: "1/11",
								display: "grid",
								gridAutoFlow: "dense",
								gridTemplateColumns: `repeat(${variant?.list_details?.cols}, 1fr)`,
								gridTemplateRows: `repeat(${variant?.list_details?.rows}, 1fr)`,
								gridGap: "2px",
								width: "100%",
								height: "100%",
								flex: 1,
								gridAutoRows: "0",
								overflowY: "hidden",
								textOverflow: "ellipsis",
								cursor: copyStyles === true ? "none" : "pointer",
							}}
							variants={container}
							initial="hidden"
							animate="show"
							className="button"
							onMouseEnter={() =>
								copyStyles === true ? onCursor("left") : null
							}
							onMouseLeave={copyStyles === true ? onCursor : null}
						>
							{variant?.list_details?.buttons
								?.filter((button) => button.pageNo === page)
								.map((item, index) => (
									<NewGridItem
										item={item}
										key={item.id}
										id={item.id}
										activeId={activeId}
										{...props}
										handleOpen={handleOpen}
										disableSelection={variant?.list_details?.location === "F3"}
									/>
								))}
						</motion.div>
					</SortableContext>

					<DragOverlay>
						{activeId ? <DragOverlayItem id={activeId} /> : null}
					</DragOverlay>
				</>
			)}
		</>
	);
}

